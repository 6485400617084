import React from 'react';
import Button from '../../shared/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { providers, hellobar } from '../../shared/providers';
import '../../assets/styles/NewContactList.scss';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import useAPI from '../../utils/api';
import AppLoader from '../../shared/AppLoader';
import ProviderForm from '../Contacts/ProviderForm';
import Provider from '../Contacts/Provider';
import { useSnackbar } from 'notistack';
import { openSignInWindow } from './ConnectPopup';
import { extractPlanTier } from '../Editor/utils';
import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import PaywallLock from '../PaywallLock';
import Tooltips from '../Tooltips';
import { useRoleCheck } from '../../utils/useRoleCheck';
import ContactEmailSelector from '../Contacts/ContactEmailSelector';
import { useHistory } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export default function ContactListDialog(props) {
  const { buttonColor, buttonTitle, onConfirm, changeProvider } = props;
  const api = useAPI();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [showProviders, setShowProviders] = React.useState(false);
  const [showSettings, setShowSettings] = React.useState(
    props.showSettings || false
  );
  const [loading, setLoading] = React.useState(false);
  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [contactList, setContactList] = React.useState(props.contactList);
  const [remoteLists, setRemoteLists] = React.useState(props.lists);
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(api.currentRole);

  const siteId = api.siteId;
  const planTier = extractPlanTier(api.plan);
  const isFreePlan = api.plan.free || api.plan.free_forever;
  const shouldBuyEmailValidationCredits =
    !isFreePlan && api.site.validate_email_credit === 0;

  const handleClickOpen = (event) => {
    // To check allowed role to perform action
    if (handleRoleCheck()) {
      return;
    }
    event.preventDefault();
    setOpen(true);
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setOpen(false);
    setContactList({ ...props.contactList });
  };

  const handleNameChange = (event) => {
    setContactList({ ...contactList, name: event.target.value });
  };

  const handleEmailEnabled = () => {
    if (!contactList.email_enabled) {
      setContactList({ ...contactList, email_recipients: [] });
    }
    setContactList({
      ...contactList,
      email_enabled: !contactList.email_enabled,
    });
  };

  const handleValidateEmail = () => {
    isFreePlan
      ? history.push(`/sites/${siteId}/upgrade`)
      : shouldBuyEmailValidationCredits
      ? history.push(`/sites/${siteId}/email-validation-credit`)
      : setContactList({
          ...contactList,
          validate_email: !contactList.validate_email,
        });
  };

  const handleProviderChange = async (provider) => {
    setLoading(true);
    let [, connection] = await api.contactListConnection(provider.key);
    if (provider.key === 'webhooks') {
      connection.webhook_method =
        contactList.connection.webhook_method || 'post';
    }
    setContactList({ ...contactList, connection, provider });
    const [, lists] = await api.connectionLists(provider.key);
    setRemoteLists(lists);
    setShowSettings(true);
    setLoading(false);
  };

  const setHellobar = () => {
    handleProviderChange(hellobar);
    setShowProviders(false);
  };

  const chooseProvider = (provider) => {
    handleProviderChange(provider);
    setShowProviders(false);
    setShowSettings(true);
  };

  const handleEmailRecipientChange = (recipients) => {
    setContactList({ ...contactList, email_recipients: recipients });
  };

  const handleConnect = async (event) => {
    setButtonLoading(true);
    if (contactList.provider.settings.oauth) {
      const url = `${process.env.REACT_APP_AUTH_HOST}/auth/${contactList.provider.key}`;
      try {
        const data = await openSignInWindow(url, 'connect');
        const [, response] = await api.connectContactList({
          provider: contactList.provider.key,
          oauth_data: data,
        });

        contactList.connection = { ...contactList.connection, ...response };
        setContactList({ ...contactList });
        const [, lists] = await api.connectionLists(
          contactList.connection.provider
        );
        setRemoteLists(lists);
        setButtonLoading(false);
      } catch {
        setButtonLoading(false);
      }
    } else {
      const [ok, response] = await api.connectContactList({
        provider: contactList.provider.key,
        connection: contactList.connection,
      });

      if (ok) {
        contactList.connection = { ...contactList.connection, ...response };
        setContactList({ ...contactList });
        const [, lists] = await api.connectionLists(
          contactList.connection.provider
        );
        setRemoteLists(lists);
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
      setButtonLoading(false);
    }
  };

  const handleProviderSettingsChange = (event) => {
    const { connection } = contactList;
    if (event.target.id === 'double_optin') {
      connection['double_optin'] = event.target.value;
    }
    if (event.target.name === 'webhook_method') {
      connection['webhook_method'] = event.target.value;
    } else {
      connection[event.target.id] = event.target.value;
    }
    setContactList({ ...contactList, connection });
  };

  const validateEmailRecipients = (emails) => {
    return emails.filter((email) => emailRegex.test(email));
  };

  const handleConfirm = (event) => {
    setButtonLoading(true);
    if (contactList.email_recipients?.length) {
      contactList.email_recipients = validateEmailRecipients(
        contactList.email_recipients
      );
    }
    onConfirm(contactList)
      .then((...args) => {
        setButtonLoading(false);
        setOpen(false);
        setContactList({ ...props.contactList });
      })
      .catch((error) => {
        enqueueSnackbar(error.message || error.exception, { variant: 'error' });
        setButtonLoading(false);
      });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (event.target.checkValidity()) {
      if (contactList.connection.connected) {
        handleConfirm(event);
      } else {
        handleConnect(event);
      }
    }
  };

  return (
    <React.Fragment>
      {!props.button && (
        <Button color={buttonColor} onClick={handleClickOpen}>
          {buttonTitle}
        </Button>
      )}
      {props.button && props.button(handleClickOpen)}

      <Dialog
        fullWidth={false}
        maxWidth="md"
        className="NewContactList"
        TransitionComponent={Transition}
        open={open}
        onClose={handleCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <form onSubmit={handleSubmit} autoComplete="off">
          <DialogTitle id="alert-dialog-title">
            Set up your contact list and integration
          </DialogTitle>
          <DialogContent>
            <FormControl fullWidth className="NameInput">
              <TextField
                required
                value={contactList.name}
                onChange={handleNameChange}
                id="contact_list_name"
                autoFocus
                label="What would you like to name your List?"
                size="small"
              />
            </FormControl>
            <FormControl fullWidth className="EmailEnableInput">
              {planTier < 3 && (
                <div className="email_enable_input">
                  <PaywallLock
                    message="Not available on your plan. Please upgrade your subscription."
                    url={siteId ? `/sites/${siteId}/upgrade` : ''}
                  />
                </div>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    checked={contactList.email_enabled}
                    id="contact_list_email_enabled"
                    onChange={planTier < 3 ? undefined : handleEmailEnabled}
                    size="small"
                    disabled={planTier < 3}
                  />
                }
                label="Send an email to account admins when a new lead is collected"
              />
              <Tooltips
                icon={true}
                className="email_input_message"
                message="Get notified by an email when a new lead is collected"
              />
            </FormControl>
            {contactList.email_enabled && (
              <FormControl fullWidth className="EmailUsersInput">
                <Typography variant="subtitle1" sx={{ mb: 1 }}>
                  Select email recipients
                </Typography>
                <ContactEmailSelector
                  users={contactList.email_recipients || []}
                  onChange={handleEmailRecipientChange}
                  placeholder="Select Emails"
                  dropdownList={props.siteUsers}
                />
              </FormControl>
            )}
            {api.site?.opted_in_to_email_digest && (
              <FormControl fullWidth className="EmailValidationEnableInput">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={contactList.validate_email}
                      id="contact_list_validate_email"
                      onChange={handleValidateEmail}
                      size="small"
                      disabled={props.disableValidateEmail}
                    />
                  }
                  label="Validate email deliverability to ensure a clean email list"
                />
                <Tooltips
                  icon={true}
                  className="email_input_message"
                  message="We'll validate the deliverabity of the email entered in the lead form by your visitor and add the results in the contact list"
                />
              </FormControl>
            )}
            {changeProvider && (
              <p>
                After Hello Bar collects an email address, where should we send
                it?
              </p>
            )}
            {contactList.provider === hellobar && !showProviders && (
              <div className="HellobarProvider">
                <img src={hellobar.icon} alt={hellobar.title} />
                <p>
                  We will store your email addresses in Hello Bar. You can
                  download the emails from the Contacts dashboard whenever you
                  want.
                </p>
              </div>
            )}
            {showProviders && (
              <div className="Providers">
                {providers.map((provider) => (
                  <Provider
                    key={provider.key}
                    className={
                      provider.key == contactList.provider.key
                        ? 'Provider--selected'
                        : ''
                    }
                    provider={provider}
                    onChooseProvider={chooseProvider}
                    planTier={planTier}
                    siteId={siteId}
                  />
                ))}
              </div>
            )}
            {<AppLoader visible={loading} />}
            {showSettings && !loading && (
              <div className="ProviderSettings">
                <Provider
                  disabled
                  className="Provider--selected"
                  provider={contactList.provider}
                  planTier={planTier}
                  siteId={siteId}
                />
                <ProviderForm
                  lists={remoteLists}
                  onChange={handleProviderSettingsChange}
                  provider={contactList.provider}
                  connection={contactList.connection}
                />
                {contactList?.provider?.key === 'hubspot' ? (
                  <p style={{ textAlign: 'center' }}>
                    If you want to add any custom fields at subscription time
                    please add it from hubspot contact tables edit properties
                    menu
                  </p>
                ) : contactList?.provider?.key === 'zapier' &&
                  !contactList?.connection?.webhook_url ? (
                  <p style={{ textAlign: 'center' }}>
                    *Save this list without the Webhook URL and finish the
                    connection in Zapier by choosing this contact list as target
                    in your automation.
                    <br />
                    Until finished we'll save the leads in hellobar only.
                  </p>
                ) : (
                  ''
                )}
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="white">
              Cancel
            </Button>
            {showProviders && (
              <Button color="white" onClick={setHellobar}>
                I don't use any of these email tools
              </Button>
            )}
            {changeProvider && !showProviders && (
              <Button
                color="primary"
                onClick={() => {
                  setShowSettings(false);
                  setShowProviders(true);
                }}
              >
                Show all tools
              </Button>
            )}
            {!contactList.connection.connected && (
              <Button type="submit" loading={buttonLoading}>
                Connect
              </Button>
            )}
            {contactList.connection.connected && (
              <Button type="submit" loading={buttonLoading}>
                Save
              </Button>
            )}
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
