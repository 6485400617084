import ContactListDialog from './ContactListDialog';
import { providers, hellobar } from '../../shared/providers';
import useFetch from '../../utils/useFetch';
import React from 'react';

export default function EditContactList(props) {
  const provider =
    providers.find((p) => p.key === props.contactList.provider) || hellobar;
  const contactList = {
    ...props.contactList,
    provider: provider,
  };
  const { status, data } = useFetch('connectionLists', provider.key);

  return (
    (status === 'fetched' || status === 'error') && (
      <ContactListDialog
        {...props}
        lists={data}
        showSettings={true}
        contactList={contactList}
        changeProvider={true}
        disableValidateEmail={contactList.validate_email}
      />
    )
  );
}
