import React, {useContext, useMemo, useState} from 'react';
import PopupItem from './PopupItem';
import EditRuleDialog from '../Modals/EditRuleDialog';
import {editorURL} from '../../utils/editor';
import useFetch from '../../utils/useFetch';
import AppLoader from '../../shared/AppLoader';
import {withErrorBoundary} from '../../shared/ErrorFallback';
import {ManageContext, SearchContext, SiteContext} from '../../utils/contexts';
import { getTitleValue } from '../../utils/ruleConditions';
import styled from "styled-components";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Fade from '@mui/material/Fade';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip { ...props } classes={ { popper: className } }/>
))(({ theme }) => ({
  [`& .${ tooltipClasses.tooltip }`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    marginTop: '0px !important',
    marginLeft: '15px',
    fontSize: 12,
    border: '1px solid #dadde9',
    maxWidth: '400px',

    'span': {
      paddingLeft: '20px',
      paddingTop: '10px',
      paddingBottom: '10px'
    }
  }
}));

function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return Array.from(map);
}

function PopupItemGroup({ rule, popups, togglePopup, deletePopup, copyPopup }) {
  const letter = (index, popups) =>
    popups.length > 1 ? String.fromCharCode(65 + index) : null;
  const winner =
    popups.length > 1 &&
    popups.sort((a, b) => (a.conversion_rate > b.conversion_rate ? -1 : 1))[0];

  return (
    <React.Fragment>
      {popups.map((popup, index) => (
        <React.Fragment key={popup.id}>
          <PopupItem
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
            popup={popup}
            rule={rule}
            isWinner={popup?.id === winner?.id}
            letter={letter(index, popups)}
          />
        </React.Fragment>
      ))}
      <tr className="spacer"></tr>
    </React.Fragment>
  );
}

const filterPopups = (popups, query) => {
  return popups.filter(popup => {if (
    popup.title.toLowerCase().includes(query.toLowerCase()) ||
    popup?.name?.toLowerCase()?.includes(query.toLowerCase()) ||
    popup?.tags?.toString().toLowerCase()?.includes(query.toLowerCase())
  ){
    return popup
  }})
}

function RuleItems({ rule, scope, togglePopup, deletePopup, copyPopup }) {
  const {updateRules} = useContext(ManageContext)
  const {query} = useContext(SearchContext)
  const [updated, setUpdated] = useState();
  const { loading, status, data } = useFetch(
    'rulePopups',
    rule.id,
    scope,
    updated
  );
  const popups = useMemo(() => (query !== '' || query !== undefined) ? filterPopups(data, query) : data || [],
                          [data, query, rule.name]);
  
  const groupedBySubtype = useMemo(
    () => groupBy(popups, (p) => [p.subtype, p.is_ab_test ? true : p.id].join('')),
    [popups]
  );

  return ( <>
    {( query === '' || popups.length > 0 ) && <React.Fragment>
      <tr className="rule-block">
        <th className="page-header" colSpan="10">
          <div className="rule-description">
            <h4>{rule.name}</h4>
            {
              rule.conditions.length < 2 ?
                <span>{getTitleValue(rule).length < 1 ? rule.description : getTitleValue(rule)}</span> :
                <LightTooltip
                  title={ getTitleValue(rule).join(", ") }
                  placement="bottom-start"
                  TransitionComponent={ Fade }
                  TransitionProps={ { timeout: 600 } }>
                  <span>{ getTitleValue(rule)[0]} & {getTitleValue(rule).length - 1} others</span>
                </LightTooltip>
            }
            {rule.editable && (
              <EditRuleDialog
                onUpdate={() => {
                  updateRules();
                  setUpdated(!updated);
                }}
                rule={rule}
              />
            )}
          </div>
          <AppLoader visible={loading} />
        </th>
      </tr>
      {status === 'fetched' &&
        groupedBySubtype.map(([key, popups]) => (
          <PopupItemGroup
            key={key}
            rule={rule}
            popups={popups}
            togglePopup={togglePopup}
            deletePopup={deletePopup}
            copyPopup={copyPopup}
          />
        ))}
      {status === 'fetched' && (
        <tr className="temp-block suggestion-block">
          <SiteContext.Consumer>
            {(site) => (
              <td colSpan="10">
                {/* {popups.length > 0 && (
                  <a
                    href={editorURL(
                      site,
                      '/new/goal',
                      { rule_id: rule.id }
                    )}
                  >
                    A/B test a new bar for this popup to maximize your
                    conversions
                  </a>
                )} */}
                <a
                  href={editorURL(
                    site,
                    '/new/goal',
                    { rule_id: rule.id }
                  )}
                >
                  Create a new popup in this targeting rule
                </a>
              </td>
            )}
          </SiteContext.Consumer>
        </tr>
      )}
    </React.Fragment>}
    </>
  );
}

export default withErrorBoundary(RuleItems, (Component) => {
  return (props) => {
    return (
      <tr>
        <th colSpan="10">
          <Component {...props} />
        </th>
      </tr>
    );
  };
});
