import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import ExpeditionLogo from '../assets/images/expedition_logo.png';
import { Typography } from '@mui/material';
import { Cookie } from '../utils/cookie';

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  padding: 5px;
  svg {
    cursor: pointer;
  }
`;

const ExpeditionBar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    handleCookie();
  };

  const copyToClip = () => {
    navigator.clipboard.writeText('FOUNDER70');
    enqueueSnackbar('Copied to clipboard. Use the code on the payment page.', {
      variant: 'success',
    });
    window.open(
      'https://community.expedition.so/checkout/membership',
      '_blank'
    );
  };

  const handleBecomeAMember = () => {
    copyToClip();
    handleClose();
  };

  const handleCookie = () => {
    Cookie.set('expedition_subscribed', true);
  };

  const isSubscribed = Cookie.get('expedition_subscribed');

  return (
    open &&
    !isSubscribed && (
      <div className="expedition">
        <div>
          <img src={ExpeditionLogo} />
        </div>
        <div className="hb-content">
          <Typography variant="h5">
            Join Expedition by Hello Bar - the premier community for growth
            leaders.
          </Typography>
          <Typography variant="h6">
            Become a Founding Member at 70% off for life - Limited Time Offer!
            use code{' '}
            <strong>
              <u onClick={(e) => copyToClip()}>FOUNDER70</u>
            </strong>
          </Typography>
        </div>
        <div className="button-group">
          <a
            className="button become-a-member"
            onClick={() => handleBecomeAMember()}
          >
            Become a Member
          </a>
        </div>
        <div></div>
        <CloseButton onClick={handleClose}>
          <CloseIcon />
        </CloseButton>
      </div>
    )
  );
};

export default ExpeditionBar;
