import FormControl from '@mui/material/FormControl/FormControl';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import React, { useState, useEffect } from 'react';
import { TextField } from '@mui/material';
import styled from 'styled-components';
import { icons } from '../../Contacts/icons';
import NewContactList from '../../Modals/NewContactList';
import useAPI from '../../../utils/api';
import useFetch from '../../../utils/useFetch';
import { EditCurrentContactList } from './EditCurrentContactList';

const StyledFormControl = styled(FormControl)`
  width: 100%;
`;

const ContactListOption = styled.div`
  display: grid;
  grid-template-columns: 2em 1fr;
  grid-column-gap: 0.5em;
  align-items: center;
`;

const CreateNewContactList = styled.div`
  color: #27ab9e;
  margin: 1em 0 0em 0;
  cursor: pointer;
`;

export default function ContactListsInput({ value, onChange }) {
  const api = useAPI();
  const [update, setUpdate] = useState(value);
  const [contactLists, setContactList] = useState([]);
  const { loading, status, data } = useFetch('contactLists', 'all', update);

  const prepareContactLists = (items) =>
    items.map((contactList) => {
      return { ...contactList, label: contactList.name, value: contactList.id };
    });

  useEffect(() => {
    setContactList(prepareContactLists(data));
  }, [loading, status, data]);

  useEffect(() => {
    if (typeof update === 'number') {
      onChange(update);
    } else {
      onChange(update?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  const contactList = contactLists.find(({ id }) => id === value);

  const saveContactList = async (contactList) => {
    const data = {
      identity_id: contactList.connection.id,
      provider_token: contactList.provider.key,
      name: contactList.name,
      email_enabled: contactList.email_enabled,
      email_recipients: contactList.email_recipients,
      data: {
        webhook_url: contactList.connection.webhook_url,
        webhook_method: contactList.connection.webhook_method,
        username: contactList.connection.username,
        api_key: contactList.connection.api_key,
        api_secret: contactList?.connection?.credentials?.secret,
        app_url: contactList.connection.app_url,
        embed_code: contactList.connection.embed_code,
        list_api_id: contactList.connection.list_api_id,
        remote_id: contactList.connection.remote_id,
      },
    };

    const [ok, response] = await api.createContactList(data);
    if (ok) {
      setUpdate(response);
    } else {
      throw response;
    }
  };

  return (
    <StyledFormControl variant="outlined">
      <TextField
        select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        size="small"
      >
        {contactLists.map((item) => (
          <MenuItem key={item.value} value={item.value} size="small">
            <ContactListOption>
              <img alt={item.provider_name} src={icons[item.provider]} />
              <span>{item.label}</span>
            </ContactListOption>
          </MenuItem>
        ))}
      </TextField>
      {contactList?.id && (
        <EditCurrentContactList
          id={contactList.id}
          setUpdateList={setUpdate}
          siteUsers={contactList.site_users}
        />
      )}
      <NewContactList
        buttonTitle="New Contact List"
        siteUsers={api.site?.site_users}
        onConfirm={saveContactList}
        validateEmail={api.site?.validate_email}
        button={(handleClickOpen) => (
          <CreateNewContactList onClick={handleClickOpen}>
            Create new contact list
          </CreateNewContactList>
        )}
      />
    </StyledFormControl>
  );
}
