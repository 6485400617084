import styled from 'styled-components';
import { useContext } from 'react';
import { EditorContext } from '../../utils/contexts';
import { Section, Container, Icon } from './ThemeSelector/components';
import Theme from './ThemeSelector/Theme';
import { availableThemes } from './ThemeSelector/updateTheme';

function ThemeSelector({
  className,
  showThemeSelector,
  setCanOverrideTheme,
  setUpdateThemeContents,
  mode,
}) {
  const { settings, siteSettings, updateTheme } = useContext(EditorContext);
  const themes = availableThemes(siteSettings, settings.type).filter(
    ({ id }) => id !== 'autodetect'
  );

  const chooseTheme = (theme) => {
    updateTheme(theme.id);
    showThemeSelector(false);
    siteSettings.site_theme && setCanOverrideTheme(true);
    setUpdateThemeContents(true);
  };

  return (
    <div className={className}>
      <Container>
        <Icon onClick={() => showThemeSelector(false)}>
          <i className="icon-close" />
        </Icon>

        {/* <Section>
          <h3>Color autodetection <span>Colors grabbed from the site</span></h3>
        </Section>

        <Section>
          <AutodetectButton onClick={() => chooseTheme({id: 'autodetect'})}>
            <img alt="Autodetection" src={AutodetectionImg}/>
            <span>Autodetect colors from site</span>
          </AutodetectButton>
        </Section> */}

        <Section>
          <h3>
            {settings.type} Themes{' '}
            <span>Predefined styles for the default bar</span>
          </h3>
        </Section>

        <Section>
          {themes.map((theme) => (
            <Theme
              key={theme.id}
              type={settings.type}
              theme={theme}
              onClick={chooseTheme}
            />
          ))}
        </Section>
      </Container>
    </div>
  );
}

export default styled(ThemeSelector)`
  height: 100vh;
  background: #f1f0f0;
  position: relative;
`;
