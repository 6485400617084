import React, { useEffect } from 'react';
import { tracking } from '../../../utils/tracking';
import useAPI from '../../../utils/api';
import StripeCheckoutSuccess from './StripeCheckoutSuccess';
import PaymentMethod from './PaymentMethod';
import Button from '../../../shared/Button';
import SiteLink from '../../../shared/SiteLink';
import PayPalAccount from './PayPalAccount';
import Box from '@mui/material/Box';
import { useSnackbar } from 'notistack';

const statusTexts = {
  past_due: 'Subscription is past due. Please check your payment method.',
  active: 'Subscription is active.',
  trialing: 'Subscription is in trial.',
  incomplete: 'Subscription is inactive. Please check your payment details',
};

function CurrentPlan({ subscription, onUpdate }) {
  const API = useAPI();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const RAAFT_APP_ID = process.env.REACT_APP_RAAFT_APP_ID;
    (function (r, aa, f, t, dot) {
      r[dot] =
        r[dot] ||
        function () {
          (r[dot].q = r[dot].q || []).push(arguments);
        };
      r[dot].l = 1 * new Date();
      const i = aa.createElement(f);
      const o = aa.getElementsByTagName(f)[0];
      i.id = 'raaftScript';
      i.async = true;
      i.src = t;
      o.parentNode.insertBefore(i, o);
    })(
      window,
      document,
      'script',
      '//app.raaft.io/include.js?appId=' + RAAFT_APP_ID,
      'raaft'
    );
  });

  const { plan } = subscription;

  const text = {
    ELT: 'If you’re looking for additional features or monthly views, get in touch with our team and we can work on creating a custom plan for you.',
    GRW: 'If you’re looking for more monthly views or additional features check out our Premium Plan',
    PRM: 'If you’re looking for more monthly views or additional features check out our Elite Plan',
  }[plan.label];

  const statusText =
    subscription.status === 'active' && subscription.cancel_on
      ? `${statusTexts[subscription.status]} Cancels ${subscription.cancel_on}`
      : statusTexts[subscription.status];

  const getInTouch = () => {
    tracking.freshchat.open({
      name: 'Support',
      replyText: "Hi, I'm interested in upgrading to a custom plan",
    });
  };

  const cancelSubscription = () => {
    if (subscription.raaft_auth_key) {
      window.raaft('startCancelFlow', {
        authKey: subscription.raaft_auth_key,
        subscriptionId: subscription.stripe_subscription_id,
        onComplete: (result) => {
          onUpdate();
          console.log('raaft complete:', result);
        },
      });
    } else {
      tracking.freshchat.open({
        name: 'Support',
        replyText: "Hi, I'd like to cancel my subscription",
      });
    }
  };

  const reactivateSubscription = async () => {
    const [ok, response] = await API.reactivateSubscription(subscription);

    if (ok) {
      enqueueSnackbar('Thanks! Your subscription is resumed.', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar(
        'There was a problem with your request. Please contact us.',
        {
          variant: 'error',
        }
      );
      getInTouch();
    }
  };

  return (
    <React.Fragment>
      <div className="container">
        <div>
          <div className="plan-name">{plan.name}</div>
          <div>
            With {plan.visit_overage?.toLocaleString()} popup views{' '}
            {plan.schedule === 'lifetime' ? plan.schedule : 'monthly'}
          </div>
          <div className="plan-price">{subscription.price_info}</div>
        </div>
        <div>
          <Box fontWeight="800" fontSize={16} my={1}>
            {statusText}
          </Box>

          {subscription.renew_on && (
            <div className="plan-renew-on">
              Your plan renews on {subscription.renew_on}.
            </div>
          )}
          {subscription.canceled_on && (
            <div className="plan-renew-on">
              Canceled on {subscription.canceled_on}.
            </div>
          )}
          {subscription.cancel_on && (
            <div className="plan-renew-on">
              Scheduled to cancel on {subscription.cancel_on}.
            </div>
          )}
          {subscription &&
            subscription.status !== 'canceled' &&
            subscription.amount > 0 && (
              <div className="plan-cancel">
                <div
                  className="plan-cancel-button"
                  onClick={cancelSubscription}
                >
                  Cancel Subscription
                </div>
              </div>
            )}
        </div>
      </div>

      {((!subscription.canceled_at &&
        subscription.collection_method === 'charge_automatically') ||
        subscription.status === 'trialing') && (
        <PaymentMethod
          onUpdate={onUpdate}
          paymentMethod={subscription.payment_method}
        />
      )}
      {subscription.collection_method === 'send_invoice' && (
        <PayPalAccount email={subscription.paypal_email} />
      )}

      {subscription.status === 'trialing' && (
        <div className="plan-upgrade">
          <SiteLink className="all-plans-button" to="/upgrade">
            <Button color="secondary">Upgrade now</Button>
          </SiteLink>
        </div>
      )}

      {!subscription.canceled_at && subscription.status !== 'trialing' && (
        <>
          <div className="plan-upgrade">
            <p>{text}</p>
            {plan.label === 'ELT' && (
              <Button onClick={getInTouch}>Get in touch with us</Button>
            )}
            {plan.label === 'GRW' && (
              <SiteLink to="/upgrade/premium_1-monthly">
                <Button color="secondary">Upgrade to Premium</Button>
              </SiteLink>
            )}
            {plan.label === 'PRM' && (
              <SiteLink to="/upgrade/elite_1-monthly">
                <Button color="secondary">Upgrade to Elite</Button>
              </SiteLink>
            )}
            {plan.label === 'STR' && (
              <SiteLink to="/upgrade/growth_1-monthly">
                <Button color="secondary">Upgrade to Growth</Button>
              </SiteLink>
            )}
            {plan.label === 'FFR' && (
              <SiteLink to="/upgrade/growth_1-monthly">
                <Button color="secondary">Upgrade to Growth</Button>
              </SiteLink>
            )}
            {plan.label !== 'FREE' && (
              <SiteLink className="all-plans-button" to="/upgrade">
                <Button color="white">View all plans</Button>
              </SiteLink>
            )}
            {plan.label === 'FREE' && (
              <SiteLink to="/upgrade">
                <Button color="secondary">Upgrade Now</Button>
              </SiteLink>
            )}
          </div>
        </>
      )}
      {subscription &&
        subscription.status === 'active' &&
        subscription.cancel_on && (
          <Button onClick={reactivateSubscription}>Resume Subscription</Button>
        )}
    </React.Fragment>
  );
}

function IncompletePlan({ subscription, onUpdate }) {
  const {
    incomplete_subscription: {
      plan,
      price_info,
      collection_method,
      payment_method,
    },
  } = subscription;
  const lastInvoice = subscription.invoices.find(
    (invoice) => invoice.status === 'open'
  );
  const statusText = statusTexts[subscription.status];

  return (
    <React.Fragment>
      <div className="plan-name">{plan.name}</div>
      <div>
        With {plan.visit_overage?.toLocaleString()} popup views{' '}
        {plan.schedule === 'lifetime' ? plan.schedule : 'monthly'}
      </div>
      <div className="plan-price">{price_info}</div>
      <Box fontWeight="800" fontSize={16} my={1}>
        {statusText}
      </Box>
      {collection_method === 'charge_automatically' && (
        <PaymentMethod onUpdate={onUpdate} paymentMethod={payment_method} />
      )}
      <div className="plan-upgrade">
        {lastInvoice && lastInvoice.hosted_invoice_url && (
          <a href={lastInvoice.hosted_invoice_url}>
            <Button color="secondary">Complete your subscription</Button>
          </a>
        )}
      </div>
    </React.Fragment>
  );
}

export default function PlanInfo({ subscription, onUpdate }) {
  const incomplete = !!subscription.incomplete_subscription;

  return (
    <div className="PlanInfo">
      <StripeCheckoutSuccess planId={subscription.plan.stripe_id} />
      <h5 className="page-header">Current Plan</h5>
      {!incomplete && (
        <CurrentPlan onUpdate={onUpdate} subscription={subscription} />
      )}
      {incomplete && (
        <IncompletePlan onUpdate={onUpdate} subscription={subscription} />
      )}
    </div>
  );
}
