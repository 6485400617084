import React from 'react';
import '../../assets/styles/_table_rules.scss';
import { withErrorBoundary } from "../../shared/ErrorFallback";
import RuleHeader from "./RuleHeader";
import Rules from "./Rules";

function ActiveRules({ rules, togglePopup, deletePopup, copyPopup, scope }) {
  const shouldDisplayRule = (rule) => {
    return !rule.editable || rule.active_popups_count > 0;
  }

  const actions = { togglePopup, deletePopup, copyPopup }
  const activeRules = (rules) =>
    rules.filter(shouldDisplayRule)

  return (
    <div style={{ 'padding': '24px' }}>
      <table className="rules-wrapper">
        <RuleHeader/>
        <Rules rules={activeRules(rules)} actions={actions} scope={ scope || "active" }/>
      </table>
    </div>
  )
}

export default withErrorBoundary(ActiveRules);
