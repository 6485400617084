import React from "react";

export default function RuleHeader () {
  return (
    <thead>
    <tr>
      <th className="color-flag"/>
      <th>Popup</th>
      <th>Age</th>
      <th>Goal</th>
      <th>GDPR</th>
      <th>Layout</th>
      <th>Views</th>
      <th>Conversions</th>
      <th>%</th>
      <th/>
    </tr>
    </thead>
  )
}
