import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useParams } from 'react-router';
import Manage from './components/Manage';
import Summary from './components/Summary';
import Contacts from './components/Contacts';
import ContactList from './components/Contacts/ContactList';
import Settings from './components/Settings';
import Install from './components/Install';
import UpgradeDialog from './components/Modals/UpgradeDialog';
import ServiceAddOnDialog from './components/Modals/ServiceAddOnDialog';
import StripeCheckout from './components/Modals/StripeCheckout';
import { PayPalProvider } from './utils/PayPalProvider';
import { withErrorBoundary } from './shared/ErrorFallback';
import useDocumentTitle from './utils/document-title';
import Rules from './components/Rules';
import PlanSelection from './components/Onboarding/PlanSelection';
import SiteLink from './shared/SiteLink';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import EmailValidationServiceAddOnDialog from './components/Modals/EmailValidationServiceAddOnDialog';

export function Title({ site, value }) {
  useDocumentTitle(value, site.url);
  return null;
}

const CheckForPreselectedPlan = (site) => {
  const { siteId } = useParams();
  if (
    siteId === site.id &&
    (site.plan.free || site.plan.free_forever) &&
    site.pre_selected_plan &&
    (site.pre_selected_plan.includes('financial-') ||
      site.pre_selected_plan.includes('starter_plus-'))
  ) {
    return (
      <Redirect
        to={'/sites/' + site.id + '/upgrade/' + site.pre_selected_plan}
      />
    );
  }
};

const ForceTrialSelection = (site) => {
  const { siteId } = useParams();
  if (
    siteId == site.id &&
    (site.plan.free_forever || site.plan.free) &&
    site.created_at > '2024-08-27T00:00:00.000Z'
  ) {
    return <Redirect to={`/sites/${siteId}/select-plan`} />;
  }
};

const CheckSiteUserRole = (site) => {
  if (site.current_role === 'viewer') {
    return <Redirect to={`/sites/${site.id}`} />;
  }
};

const CheckSiteBillingUserRole = (site) => {
  const location = useLocation();
  if (
    site.current_role === 'viewer' &&
    location.pathname.includes('settings/billing')
  ) {
    return <Redirect to={`/sites/${site.id}`} />;
  }
};

const redirectToSiteSettings = (site) => {
  return <Redirect to={`/sites/${site.id}/settings/site`} />;
};

function SiteRouter({ user, site }) {
  return (
    <Switch>
      <Redirect
        exact
        from="/sites/:siteId"
        to={
          // sessionStorage.getItem('session_signed_up') &&
          // site.plan.free &&
          // site.partner !== 'dsitebuilder' &&
          // site.partner !== 'vmarketplace'
          //   ? '/sites/:siteId/select-plan'
          //   :
          '/sites/:siteId/summary'
        }
      />

      <Redirect
        exact
        from="/sites/:siteId/upgrade/free"
        to="/sites/:siteId/summary"
      />

      <Route path="/sites/:siteId/select-plan">
        {CheckSiteUserRole(site)}
        {/* {CheckForPreselectedPlan(site)}
        {ForceTrialSelection(site)} */}
        <Title site={site} value="Select your Plan" />
        <PlanSelection site={site} user={user} />
      </Route>

      <Route path="/sites/:siteId/install">
        {ForceTrialSelection(site)}
        <Title site={site} value="Install" />
        <Install site={site} user={user} />
      </Route>

      <Route path="/sites/:siteId/summary">
        {ForceTrialSelection(site)}
        {CheckForPreselectedPlan(site)}
        <Title site={site} value="Summary" />
        <Summary site={site} />
      </Route>

      <Route path="/sites/:siteId/manage">
        {ForceTrialSelection(site)}
        <Title site={site} value="Manage" />
        <Manage site={site} />
      </Route>

      <Route path="/sites/:siteId/rules">
        {ForceTrialSelection(site)}
        {CheckForPreselectedPlan(site)}
        <Title site={site} value="Rules" />
        <Rules site={site} />
      </Route>

      <Route exact path="/sites/:siteId/contacts">
        {ForceTrialSelection(site)}
        <Title site={site} value="Contacts" />
        <Contacts site={site} />
      </Route>

      <Route exact path="/sites/:siteId/contacts/inactive">
        {ForceTrialSelection(site)}
        <Title site={site} value="Contacts" />
        <Contacts site={site} scope={'inactive'} />
      </Route>

      <Route exact path="/sites/:siteId/contacts/all">
        {ForceTrialSelection(site)}
        <Title site={site} value="Contacts" />
        <Contacts site={site} scope={'all'} />
      </Route>

      <Route path="/sites/:siteId/contacts/:id">
        {ForceTrialSelection(site)}
        <Title site={site} value="Contact List" />
        <ContactList user={user} />
      </Route>

      <Route path="/sites/:siteId/settings">
        {ForceTrialSelection(site)}
        {CheckSiteBillingUserRole(site)}
        {['dsitebuilder', 'tcoursebuilder', 'shopify'].includes(site.partner) &&
          redirectToSiteSettings(site)}
        <Title site={site} value="Settings" />
        <Settings site={site} user={user} />
      </Route>

      <Route exact path="/sites/:siteId/upgrade">
        <Title site={site} value="Upgrade" />
        <UpgradeDialog />
      </Route>

      <Route exact path="/sites/:siteId/onboarding-add-on">
        <Title site={site} value="Onboarding" />
        <ServiceAddOnDialog site={site} isOpen={true} />
      </Route>

      <Route exact path="/sites/:siteId/email-validation-credit">
        <Title site={site} value="Onboarding New Add Ons" />
        <EmailValidationServiceAddOnDialog site={site} isOpen={true} />
      </Route>

      <Route exact path="/sites/:siteId/upgrade/:planId">
        {CheckSiteUserRole(site)}
        <SiteLink to="/settings/billing" className="go-back-link">
          <ArrowBackIosIcon />
          Back
        </SiteLink>
        <PayPalProvider>
          <StripeCheckout site={site} />
        </PayPalProvider>
      </Route>
    </Switch>
  );
}

export default withErrorBoundary(SiteRouter);
