import { useState } from 'react';
import {
  Button,
  ButtonOverlay,
  ThemeName,
  ThemeTileWrapper,
  Thumbnail,
} from './components';

const images = require.context('../../../assets/images/themes/tiles', true);

export default function Theme({ type, theme, onClick }) {
  const [visible, setVisible] = useState(false);
  const imgSrc = images(`./${type.toLowerCase()}/${theme.id}.png`).default;

  return (
    <ThemeTileWrapper
      className={type}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <div className="theme-tile">
        {theme.new && (
          <div class="ribbon">
            <span class="ribbon__content">new</span>
          </div>
        )}
        <Thumbnail className="thumbnail" alt="Theme thumbnail" src={imgSrc} />
        <ThemeName className={type}>{theme.name}</ThemeName>
        <ButtonOverlay visible={visible}>
          <Button onClick={() => onClick(theme)}>Select</Button>
        </ButtonOverlay>
      </div>
    </ThemeTileWrapper>
  );
}
