import CollapsedPanel from "../components/CollapsedPanel";
import React, {Fragment, useContext} from "react";
import {Label, LabelSelector} from "../Steps/components";
import Switcher from "../components/Switcher";
import {EditorContext} from "../../../utils/contexts";
import NumberInput from "../components/NumberInput";
import InputBlock from "../components/InputBlock";
import ColorPicker from "../components/ColorPicker";
import FontPicker from "../components/FontPicker";
import {TextField} from "@mui/material";

export default function CountdownPanel() {
  const {settings, updateSettings} = useContext(EditorContext)

  const toggleCountdownTimer = () => {
    updateSettings({ show_countdown_timer: !settings.show_countdown_timer })
  }

  const updateFont = (value) => {
    updateSettings({ timer_font_family: value })
  }

  const updateFontColor = (value) => {
    updateSettings({ timer_text_color: value })
  }

  const updateFontSize = (value) => {
    updateSettings({ timer_text_size: value })
  }

  const updateEndDate = (event) => {
    updateSettings({ timer_ends_at: event.target.value })
  }

  return (
    <CollapsedPanel open={false} title="Countdown Timer">
      <Label>
        <span>Show Default Countdown Timer</span>
        <Switcher checked={settings.show_countdown_timer} onChange={toggleCountdownTimer}/>
      </Label>
      <Fragment>
        <LabelSelector>
          <TextField
            id="datetime-local"
            label="End date"
            type="datetime-local"
            value={(settings.timer_ends_at||"").slice(0, 16)}
            onChange={updateEndDate}
            InputLabelProps={{
              shrink: true
            }}
            size="small"
          />
        </LabelSelector>
        <InputBlock label="Font family">
          <FontPicker value={settings.timer_font_family} onChange={updateFont}/>
        </InputBlock>
        <InputBlock label="Font Color">
          <ColorPicker value={settings.timer_text_color} onChange={updateFontColor}/>
        </InputBlock>
        <InputBlock label="Font Size">
          <NumberInput value={settings.timer_text_size} onChange={updateFontSize} max={48}/>
        </InputBlock>
      </Fragment>
    </CollapsedPanel>
  )
}
