import React, { useEffect, useRef, useState, useContext } from "react";
import useOnScreen from "../../utils/use_on_screen";
import LazyRuleItems from "./LazyRuleItems";
import {ManageContext, SearchContext, SiteContext} from '../../utils/contexts'
import Button from "../../shared/Button";
import CreateRuleDialog from "../Modals/CreateRuleDialog";
import {useHistory} from "react-router-dom";

export default function Rules({ rules, scope, actions, children }) {
  const searchData = useContext(SearchContext)
  const ref = useRef()
  const isVisible = useOnScreen(ref)
  const [lastElement, setLastElement] = useState(5);
  const {updateRules} = useContext(ManageContext)
  const site = useContext(SiteContext)
  const history = useHistory()

  useEffect(() => {
    if (isVisible && searchData.query === '') {
      setLastElement(value => Math.min(rules.length, value + 5))
    }
  }, [isVisible, searchData.query, rules.length])

  const onCreatedRule = () => {
    updateRules();
    history.replace(`/sites/${site.id}/manage/all`)
  }

  return (
    <React.Fragment>
      <tbody>
      <tr/>
      {children}
      </tbody>
      {(searchData.query !== '' ? searchData.rules : rules.slice(0, lastElement)).map((rule) => {
        return <LazyRuleItems actions={actions}
                       key={rule.id}
                       rule={rule}
                       scope={scope}/>
      })}
      <tbody className="NewRuleButton">
        <tr ref={ref}>
          <td colSpan="10">
            <CreateRuleDialog onUpdate={onCreatedRule} handler={<Button>Add Targeting Rule</Button>}/>
          </td>
        </tr>
      </tbody>
    </React.Fragment>
  )
}
