import styled from 'styled-components';
import React, { useContext, useState, useEffect } from 'react';
import iconBar from '../../../assets/images/editor/type-bar.svg';
import iconInline from '../../../assets/images/editor/type-inline.svg';
import iconModal from '../../../assets/images/editor/type-modal.svg';
import iconSlider from '../../../assets/images/editor/type-slider.svg';
import iconTakeover from '../../../assets/images/editor/type-takeover.svg';
import iconAlert from '../../../assets/images/editor/type-alert.svg';
import { ListWrapper, Title, TitleHint } from './components';
import { EditorContext, SiteContext } from '../../../utils/contexts';
import { SUBTYPES } from '../constants';
import { withEditorPageTracking } from '../../../shared/Tracking';
import PaywallLock from '../../PaywallLock';
import { useHistory } from 'react-router-dom';

const icons = {
  Bar: iconBar,
  Modal: iconModal,
  Inline: iconInline,
  Slider: iconSlider,
  Takeover: iconTakeover,
  Alert: iconAlert,
};

const Type = styled(({ className, type, title, children, onClick, active }) => {
  const history = useHistory();
  const site = useContext(SiteContext);
  const icon = icons[type];
  const classes = [className, active === type ? 'active' : ''];

  // const select = () => {
  //   (site.plan.free || site.plan.free_forever) && type === 'Inline'
  //     ? history.push(`/sites/${site.id}/upgrade`)
  //     : onClick(type);
  // };

  const select = () => {
    onClick(type);
  };

  return (
    <div className={classes.join(' ')} onClick={select}>
      {/* {(site.plan.free || site.plan.free_forever) && type === 'Inline' && (
        <PaywallLock
          message="Not available on your plan. Please upgrade your subscription."
          url={''}
        />
      )} */}
      <h6>{title}</h6>
      <img src={icon} alt="" />
      <p>{children}</p>
    </div>
  );
})`
  margin-bottom: 0.67em;
  transition: 0.67s;
  border: 2px solid #dcdcdc;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.active {
    border-color: #636363;
    border-width: 2px;
  }
  img {
    padding: 10px;
    height: 60px;
    width: 60px;
  }
  h6 {
    padding-bottom: 0.25em;
    white-space: nowrap;
    font-size: 0.95em;
    line-height: 133%;
    color: #666666;
  }
  p {
    font-size: 0.9em;
    line-height: 150%;
  }
`;

function TypeStep({ className, onSelect }) {
  const { settings, systemUpdate } = useContext(EditorContext);

  const [active, setActive] = useState(settings.type);

  const onClick = (type) => {
    onSelect(type);
    setActive(type);
  };

  useEffect(() => {
    if (settings.show_thankyou === true) {
      systemUpdate({ show_thankyou: false });
    }
  }, [settings.show_thankyou, systemUpdate]);

  const Bar = (
    <Type key="Bar" active={active} type="Bar" title="Bar" onClick={onClick}>
      A customizable bar at the top or bottom of your site.
    </Type>
  );

  const Modal = (
    <Type
      key="Modal"
      active={active}
      type="Modal"
      title="Modal"
      onClick={onClick}
    >
      A pop up window in the center of your site.
    </Type>
  );

  const Slider = (
    <Type
      key="Slider"
      active={active}
      type="Slider"
      title="Slider"
      onClick={onClick}
    >
      A small window that slides out of the right or left side.
    </Type>
  );

  const Takeover = (
    <Type
      key="Takeover"
      active={active}
      type="Takeover"
      title="Page Takeover"
      onClick={onClick}
    >
      A full page pop up that your visitors can't miss.
    </Type>
  );

  const Alert = (
    <Type
      key="Alert"
      active={active}
      type="Alert"
      title="Alert"
      onClick={onClick}
    >
      A small floating button that expands when clicked on.
    </Type>
  );

  const Inline = (
    <Type
      key="Inline"
      active={active}
      type="Inline"
      title="Hello Bar Inline"
      onClick={onClick}
    >
      A pop up as embedded inline element of your site.
    </Type>
  );

  const types = {
    [SUBTYPES.CALL]: [Bar],
    [SUBTYPES.EMAIL]: [Bar, Modal, Slider, Takeover, Alert, Inline],
    [SUBTYPES.ANNOUNCEMENT]: [Bar, Modal, Slider, Takeover, Alert, Inline],
    [SUBTYPES.SOCIAL]: [Bar, Modal, Slider, Takeover, Alert, Inline],
    [SUBTYPES.TRAFFIC]: [Bar, Modal, Slider, Takeover, Alert, Inline],
    [SUBTYPES.RETIREMENT]: [Takeover],
    [SUBTYPES.LEADFORM]: [Modal, Inline],
    [SUBTYPES.WEBFORM]: [Modal, Takeover, Inline],
  };

  useEffect(() => {
    window.hellobar?.displayPage && window.hellobar?.displayPage('default');
  }, []);

  return (
    <div className={className}>
      <div>
        <Title>Type</Title>
        <TitleHint>Choose the Layout Type of your popup.</TitleHint>
      </div>
      <ListWrapper children={types[settings.subtype]} />
    </div>
  );
}

export default styled(withEditorPageTracking(TypeStep, 'Type'))``;
