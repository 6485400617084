import FormControl from "@mui/material/FormControl/FormControl";
import Select from "@mui/material/Select/Select";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import React from "react";
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  }
}));

export default function MultiSelectInput({ value, items, onChange, defaultContent }) {
  const classes = useStyles();
  return (
    <FormControl variant="outlined" size="small">
      <Select name="value" value={value} onChange={onChange} multiple displayEmpty renderValue={(selected) => ( 
        value.length < 1 ? defaultContent : <div className={classes.chips}>
          {selected.map((value) => (
            <Chip size="small" key={value} label={items.find(c => c.value === value)?.label} className={classes.chip}/>
          ))}
        </div>
      )}>
        {items.map(item => <MenuItem key={item.label} value={item.value}>{item.label}</MenuItem>)}
      </Select>
    </FormControl>
  );
}
