import styled from 'styled-components';
import React, { useContext, useEffect } from 'react';
import {
  Label,
  LabelSelector,
  ListWrapper,
  Title,
  TitleHint,
} from './components';
import { EditorContext } from '../../../utils/contexts';
import Switcher from '../components/Switcher';
import { useSnackbar } from 'notistack';
import SelectInput from '../components/SelectInput';
import {
  Tooltip,
  TextField,
  Autocomplete,
  Chip,
  FormControl,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { withEditorPageTracking } from '../../../shared/Tracking';
import '../../../assets/styles/_settings.scss';
import NumberInput from '../components/NumberInput';
import InputBlock from '../components/InputBlock';
import useFetch from '../../../utils/useFetch';
import { days } from '../utils';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(16),
  },
}))(Tooltip);

const EventInput = (props) => {
  const { enqueueSnackbar } = useSnackbar();
  const eventName = props.value.replace('event.', '');
  const updateCondition = (e) => {
    props.updateViewCondition(`event.${e.target.value}`);
  };
  const handleCopy = (event) => {
    if (event.target.select) {
      event.target.select();
    } else {
      const input =
        event.target.getElementsByTagName('input')[0] ||
        event.target.getElementsByTagName('textarea')[0];
      input?.select();
    }
    document.execCommand('copy');
    enqueueSnackbar('Copied!', { variant: 'success' });
  };
  return (
    <div className="event-input">
      <TextField
        size="small"
        name="value"
        required
        placeholder="enter event name"
        onChange={updateCondition}
        value={eventName}
        variant="outlined"
        inputProps={{
          maxLength: 20,
        }}
      />
      {eventName && (
        <div className="code">
          <label>
            Use the below code to trigger this popup on a specific visitor
            action:
          </label>
          <TextField
            onClick={handleCopy}
            value={`window.hellobar.trigger.event('${eventName}');`}
            readOnly
            multiline
            rows={2}
            size="small"
          />
        </div>
      )}
    </div>
  );
};

const AutoCompleteBlock = styled(FormControl)`
  .css-16awh2u-MuiAutocomplete-root .MuiAutocomplete-tag {
    margin: 2px;
  }
  .css-6od3lo-MuiChip-label {
    padding-left: 6px;
    padding-right: 6px;
  }
  .css-2bh5uq-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
    font-size: 15px;
    margin: 0 0 0 -6px;
  }
  .MuiAutocomplete-popper.css-1s5lphu-MuiPopper-root-MuiAutocomplete-popper-root {
    box-shadow: 2px 2px 2px 2px #404040;
  }
`;

function SettingsStep({ className, site }) {
  const { settings, updateSettings } = useContext(EditorContext);
  const { enqueueSnackbar } = useSnackbar();

  function toggleBranding() {
    updateSettings({ show_branding: !settings.show_branding });
  }

  function toggleAnimated() {
    updateSettings({ animated: !settings.animated });
  }

  function toggleABTest() {
    updateSettings({ is_ab_test: !settings.is_ab_test });
  }

  function toggleWiggle() {
    updateSettings({ wiggle_button: !settings.wiggle_button });
  }

  function toggleCloseable() {
    updateSettings({ closable: !settings.closable });
  }

  function togglePush() {
    updateSettings({ pushes_page_down: !settings.pushes_page_down });
  }

  function toggleRemains() {
    updateSettings({ remains_at_top: !settings.remains_at_top });
  }

  function toggleGdpr() {
    if (
      settings.enable_gdpr ||
      (site?.gdpr?.configured && !settings.enable_gdpr)
    ) {
      updateSettings({ enable_gdpr: !settings.enable_gdpr });
    } else {
      enqueueSnackbar(
        'Privacy settings have not been configured. Please navigate to privacy settings to enable GDPR/Double opt-in.',
        { variant: 'error' }
      );
    }
  }

  function toggleClosePopup() {
    updateSettings({ close_outside_popup: !settings.close_outside_popup });
  }

  const updateConditionSelector = (value) => {
    updateSettings({ click_selector: value });
  }

  const pushesPageText =
    !settings.placement || settings.placement === 'bar-top'
      ? 'Pushes page down'
      : 'Pushes page up';

  const isEmail = settings.subtype === 'email';
  const isNotAlert = settings.type !== 'Alert';
  const isBar = settings.type === 'Bar';
  const isSlider = settings.type === 'Slider';
  const isModal = settings.type === 'Modal';
  const isAlert = settings.type === 'Alert';
  const isInline = settings.type === 'Inline';

  const delays = [
    { value: 'immediately', label: 'Immediately' },
    {
      value: 'exit-intent',
      label: 'Exit intent (user begins to leave your site)',
    },
    {
      value: settings.view_condition?.startsWith('event.')
        ? settings.view_condition
        : 'event.',
      selected: settings.view_condition.startsWith('event.'),
      label: 'on a custom triggered event',
    },
    { value: 'onclick', label: 'On a Click'},
    { value: 'wait-5', label: '5 second delay' },
    { value: 'wait-10', label: '10 second delay' },
    { value: 'wait-15', label: '15 second delay' },
    { value: 'wait-20', label: '20 second delay' },
    { value: 'wait-25', label: '25 second delay' },
    { value: 'wait-30', label: '30 second delay' },
    { value: 'wait-45', label: '45 second delay' },
    { value: 'wait-60', label: '60 second delay' },
    { value: 'wait-120', label: '2 minutes delay' },
    { value: 'scroll-some', label: 'After scrolling a little' },
    { value: 'scroll-middle', label: 'After scrolling to middle' },
    { value: 'scroll-to-bottom', label: 'After scrolling to bottom' },
  ];

  const soundOptions = [
    { value: 'bell', label: 'Bell' },
    { value: 'none', label: 'No sound' },
  ];

  const updateDismissDuration = (value) => {
    const customSettings = settings.settings
    customSettings.cookie_settings.duration = value

    updateSettings({settings: customSettings})
  }

  const shouldShowDissmissDuration = () => {
    if (isInline) return false

    if (settings.view_condition !== 'onclick') {
      if (settings.type === 'Modal') {
        return true;
      } else if (settings.type === 'Takeover') {
        return true;
      } else {
        return settings.closable;
      }
    } else {
      return false
    }
  }

  function updateViewCondition(value) {
    updateSettings({ view_condition: value });
    if (value !== 'onclick') {
      updateConditionSelector('')
    }
  }

  function updateSound(value) {
    updateSettings({ sound: value });
  }

  function updateNotificationDelay(value) {
    updateSettings({ notification_delay: value });
  }

  const tooltips = {
    abTest:
      'Enabling this would group this popup with other AB Test enabled popups in the same rule with the same Goal',
    branding:
      'Removing branding helps increase conversions on average by 6%. Upgrade to one of our paid plans to remove the H logo from your site elements.',
    animate:
      'If enabled, your bar will display just after the page loads and drop down from the top (or pop up from the bottom) of the page. This draws your site visitors attention to the bar. If enabled, this type of animation will also occur if a user closes your bar.',
  };

  useEffect(() => {
    window.hellobar?.displayPage && window.hellobar?.displayPage('default');
  }, []);

  const {
    status,
    data: { tags },
  } = useFetch('popupTags');

  const handleTagsChange = (value) => {
    updateSettings({ ...settings, tags: value });
  };

  return (
    <div className={className}>
      <div>
        <Title>Settings</Title>
        <TitleHint>Change how your Hello Bar will be shown.</TitleHint>
      </div>
      <ListWrapper>
        {isNotAlert && (
          <Label>
            <HtmlTooltip placement="bottom" title={tooltips.branding}>
              <span>Hello Bar branding</span>
            </HtmlTooltip>
            <Switcher
              checked={settings.show_branding}
              onChange={toggleBranding}
            />
          </Label>
        )}
        <Label>
          <HtmlTooltip placement="bottom" title={tooltips.abTest}>
            <span>A/B Test Enabled</span>
          </HtmlTooltip>
          <Switcher checked={settings.is_ab_test} onChange={toggleABTest} />
        </Label>
        {!isInline && (
          <Label>
            <HtmlTooltip placement="bottom" title={tooltips.animate}>
              <span>Animate entry/exit</span>
            </HtmlTooltip>
            <Switcher checked={settings.animated} onChange={toggleAnimated} />
          </Label>
        )}
        <Label>
          <span>Wiggle button</span>
          <Switcher checked={settings.wiggle_button} onChange={toggleWiggle} />
        </Label>
        {!isAlert && !isInline && (
          <Label>
            <span>Allow visitors to hide {settings.type}</span>
            <Switcher checked={settings.closable} onChange={toggleCloseable} />
          </Label>
        )}
        {isBar && (!settings.placement || settings.placement === 'bar-top') && (
          <Label>
            <span>{pushesPageText}</span>
            <Switcher
              checked={settings.pushes_page_down}
              onChange={togglePush}
            />
          </Label>
        )}
        {isBar && (
          <Label>
            <span>Remains in place on scroll</span>
            <Switcher
              checked={settings.remains_at_top}
              onChange={toggleRemains}
            />
          </Label>
        )}
        {isEmail && (
          <Label>
            <span>GDPR Compliant/Double Opt-in</span>
            <Switcher checked={settings.enable_gdpr} onChange={toggleGdpr} />
          </Label>
        )}
        {isModal && (
          <Label>
            <span>Close By Clicking Outside Pop-Up</span>
            <Switcher
              checked={settings.close_outside_popup}
              onChange={toggleClosePopup}
            />
          </Label>
        )}
        {!isInline && (
          <LabelSelector>
            <span>When does it display?</span>
            <SelectInput
              items={delays}
              value={settings.view_condition}
              onChange={updateViewCondition}
            />
            {settings.view_condition.startsWith('event.') && (
              <EventInput
                value={settings.view_condition}
                updateViewCondition={updateViewCondition}
              />
            )}
            {settings.view_condition === 'onclick' && (
              <TextField
                size="small"
                name="click_selector"
                required
                placeholder="Enter click css selector"
                onChange={e => updateConditionSelector(e.target.value)}
                value={settings.click_selector}
                variant="outlined"
                label='CSS selector of the element'
              />
            )}
          </LabelSelector>
        )}

        {shouldShowDissmissDuration() && <LabelSelector>
          <span>After a user closes this site element, don't display it again to that user for:</span>
          <SelectInput items={days} value={settings.settings.cookie_settings.duration}
                       onChange={updateDismissDuration}/>
        </LabelSelector>}

        <LabelSelector>
          <span>Tags</span>
          <AutoCompleteBlock>
            <Autocomplete
              id="tags-standard"
              multiple
              freeSolo
              options={status === 'fetched' ? tags : []}
              getOptionLabel={(option) => option.title || option}
              value={settings.tags}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    {...getTagProps({ index })}
                    variant="outlined"
                    label={option}
                    sx={{
                      fontSize: '10px',
                      height: '20px',
                      backgroundColor: 'lightgray',
                      color: '#666666',
                    }}
                  />
                ))
              }
              onChange={(event, newValue) => {
                if (newValue.length < 6) {
                  handleTagsChange(newValue);
                } else {
                  enqueueSnackbar('You can add only 5 tags.', {
                    variant: 'error',
                  });
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    inputProps={{ ...params.inputProps, maxLength: 16 }}
                    variant="outlined"
                    label="Tags"
                    margin="normal"
                    fullWidth
                  />
                );
              }}
            />
          </AutoCompleteBlock>
        </LabelSelector>

        {isAlert && (
          <>
            <LabelSelector>
              <span>Notification</span>
              <SelectInput
                items={soundOptions}
                value={settings.sound}
                onChange={updateSound}
              />
            </LabelSelector>
            <InputBlock label="Notification delay">
              <NumberInput
                value={settings.notification_delay}
                onChange={updateNotificationDelay}
                max={600}
                type="s"
              />
            </InputBlock>
          </>
        )}
      </ListWrapper>
    </div>
  );
}

export default styled(withEditorPageTracking(SettingsStep, 'Settings'))``;
