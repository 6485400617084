import React from 'react';
import SidebarItem from './Sidebar/SidebarItem';
import Button from '../shared/Button';
import '../assets/styles/Sidebar.scss';
import '../assets/styles/_icons.scss';
import subscribersTooltip from '../assets/images/subscribers_tooltip.png';
import { editorURL } from '../utils/editor';
import NewSiteDialog from '../shared/NewSiteDialog';
import { withTracking } from '../shared/Tracking';
import { NavLink } from 'react-router-dom';
import HelpDialog from '../shared/HelpDialog';

function Sidebar(props) {
  const site = props.site;
  const showSummary = site && (site.installed || site.number_of_views > 0);

  const trackEvent = (event) => {
    event.preventDefault();
    props.trackEvent('Subscribers Button Clicked', {
      site_id: site.id,
      site_url: site.url,
    });
    window.location =
      process.env.REACT_APP_SUBSCRIBERS_HOST +
      '/users/auth/hellobar?site_url=' +
      site.url;
  };

  const demoRequest = () => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    document.head.appendChild(link);
    link.href = 'https://assets.calendly.com/assets/external/widget.css';

    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    document.head.appendChild(script);
    script.onload = function () {
      window.Calendly.initPopupWidget({
        url: 'https://calendly.com/albert-hellobar/hello-bar-assist?utm_source=hbdashboard',
      });
    };
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    return false;
  };

  return (
    <div className="sidebar">
      <div
        className={`sidebar-nav-link no-hover ${
          props.isDisable ? 'disabled' : ''
        }`}
      >
        {site && (
          <React.Fragment>
            <ul>
              {showSummary && (
                <SidebarItem
                  name="Summary"
                  path={`/sites/${site.id}/summary`}
                  className="icon-summary"
                />
              )}
              <SidebarItem
                name="Install"
                path={`/sites/${site.id}/install`}
                className="icon-installmyself"
              />
              <SidebarItem
                name="Manage"
                path={`/sites/${site.id}/manage`}
                className="icon-manage"
              />
              <SidebarItem
                name="Targeting"
                path={`/sites/${site.id}/rules`}
                className=""
              />
              <SidebarItem
                name="Contacts"
                path={`/sites/${site.id}/contacts`}
                className="icon-contacts"
              />
              <SidebarItem
                name="Settings"
                path={`/sites/${site.id}/settings`}
                className="icon-settings"
              />
              <HelpDialog isOpen={false} showIcon={true} />
            </ul>

            <div className="new-popup-wrapper">
              <NavLink to={editorURL(site, '/new/interstitial')}>
                <Button color="secondary" label="Create New Popup" />
              </NavLink>
            </div>

            <div className="demo-request">
              <Button label=" Request a Demo" onClick={demoRequest} />
            </div>

            <div className="subscribers-tooltip">
              <Button label=" Push Notifications" onClick={trackEvent} />
              <div className="subscribers-tooltip-content">
                <img src={subscribersTooltip} alt="" />
              </div>
            </div>
          </React.Fragment>
        )}
        {!site && (
          <div className="new-popup-wrapper">
            <NewSiteDialog isOpen={true} />
          </div>
        )}
      </div>
      <div className="sidebar-content">{props.children}</div>
    </div>
  );
}

export default withTracking(Sidebar);
