import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import AppLoader from '../../shared/AppLoader';
import '../../assets/styles/_contacts_page.scss';
import { icons } from './icons'
import ContactItems from './ContactItems';
import useFetch from "../../utils/useFetch";
import EditContactList from '../Modals/EditContactList'
import useAPI from "../../utils/api";
import ConfirmationDialog from "../Modals/ConfirmationDialog";
import { useSnackbar } from 'notistack';
import { withTracking } from "../../shared/Tracking";
import { useRoleCheck } from '../../utils/useRoleCheck';
import { Stack } from "@mui/material";
import TablePaginationActions from '../TablePaginationActions';

function ContactList({user}) {
  const api = useAPI();
  const { id } = useParams();
  const [update, setUpdate] = useState();
  const RECORDS_PER_PAGE = 50;
  const [page, setPage] = useState({});
  const queryString = page !== undefined ? Object.keys(page)
    .map(key => `{${encodeURIComponent(key)}: ${encodeURIComponent(JSON.stringify(page[key]))}}`)
    .join(',') : ''
  const { loading, status, data: { name, email_enabled, provider_name, provider, connection, contacts, popups, validate_email, email_recipients, site_users } } = useFetch('contactList', id, queryString, update);
  const contactList = {name, email_enabled, provider_name, provider, connection, contacts, validate_email, email_recipients, site_users};
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleRoleCheck = useRoleCheck(api.currentRole);
  const [pageNo, setPageNo] = useState(1);

  const totalPages = Math.ceil(contacts?.total > 0 ? contacts?.total / RECORDS_PER_PAGE : 0);

  const handlePageChange = (e, value) => {
    setPage(value);
  }

  const handlePageNo = (value) => {
    setPageNo(value)
  }

  const saveContactList = async (contactList) => {
    const data = {
      identity_id: contactList.connection.id,
      provider_token: contactList.provider.key,
      name: contactList.name,
      double_optin: contactList.connection.double_optin,
      email_enabled: contactList.email_enabled,
      validate_email: contactList.validate_email,
      email_recipients: contactList.email_recipients,
      data: {
        webhook_url: contactList.connection.webhook_url,
        webhook_method: contactList.connection.webhook_method,
        username: contactList.connection.username,
        api_key: contactList.connection.api_key,
        api_secret: contactList.connection.api_secret,
        app_url: contactList.connection.app_url,
        embed_code: contactList.connection.embed_code,
        list_api_id: contactList.connection.list_api_id,
        remote_id: contactList.connection.remote_id
      }
    }

    const [ok, response] = await api.updateContactList(id, data);

    if (ok) {
      setUpdate(response);
    } else {
      throw response
    }
  };

  const handleExport = async () => {
    const [ok, response] = await api.exportContactList(id);

    if (ok) {
      enqueueSnackbar(`We will email you the list of your contacts to ${user.email}. This may take some time during peak hours.`, { variant: 'success' });
    } else {
      enqueueSnackbar(response?.message || response.error, { variant: 'error' });
    }
  };

  const handleDelete = async () => {
    // To check allowed role to perform action
    if (handleRoleCheck()) { return; }
    const [ok, response] = await api.deleteContactList(id);

    if (ok) {
      enqueueSnackbar("Contact list has been deleted.", { variant: 'success' });
      history.push(`/sites/${api.siteId}/contacts`)
    } else {
      enqueueSnackbar(response?.message || response.error, { variant: 'error' });
    }
  };

  return (
    <React.Fragment>
      <AppLoader visible={loading}/>
      {status === 'fetched' && <React.Fragment>
        <div className='page-header'>
          <h5>{name}</h5>
        </div>

        <div className="service-block">
          <div className="service-icon">
            <img src={icons[provider]} alt={provider_name}/>
          </div>
          <div className="service-description">
            <small>Storing contacts in</small>
            <span>{provider_name}</span>
            <small>from {popups.length} popups</small>
          </div>
          <div className="service-actions">
            <EditContactList contactList={contactList} buttonTitle="Edit" onConfirm={saveContactList} siteUsers={contactList.site_users}/>
            <ConfirmationDialog onConfirm={handleExport} buttonTitle="Export CSV">
              We will send you a CSV file with all your contacts in this list.<br/>
              This may take some time during peak hours.
            </ConfirmationDialog>
            <ConfirmationDialog onConfirm={handleDelete} buttonColor="primary" buttonTitle="Delete">
              Are you sure you want to delete this contact list and all the contacts?
            </ConfirmationDialog>
          </div>
        </div>

        <div className="spacer"></div>
        <Stack spacing={2} flexDirection={'row-reverse'}>
          {(contacts.total > contacts?.items?.length)
            ? <TablePaginationActions
                page={page}
                pageDetails={contacts}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                currentPage={pageNo}
                handlePageNo={handlePageNo}
              />
            : null
          }
        </Stack>
        <table className="contacts-table">
          <thead>
            <tr>
              <th className="header">#</th>
              {contacts.headers.map(header => <th className="header" key={header.key}>{header.title}</th>)}
              <th className="header">Date Subscribed</th>
            </tr>
          </thead>
          <tbody>
            {contacts.total === 0 && <tr>
              <td colSpan="9"><h6>No Emails Collected Yet</h6></td>
            </tr>}
            {contacts.total > 0 && <ContactItems list={contacts} recordNo={((pageNo - 1 ) * RECORDS_PER_PAGE)} />}
          </tbody>
        </table>
        <Stack spacing={2} flexDirection={'row-reverse'}>
          {(contacts.total > contacts?.items?.length)
            ? <TablePaginationActions
                page={page}
                pageDetails={contacts}
                onPageChange={handlePageChange}
                totalPages={totalPages}
                currentPage={pageNo}
                handlePageNo={handlePageNo}
              />
            : null
          }
        </Stack>
      </React.Fragment>}
    </React.Fragment>
  );
}

export default withTracking(ContactList);
