import React from 'react';
import styled from "styled-components";
import logo from "../../../../assets/images/h-logo.svg";

const TopNav = styled.div`
    position: relative;
    overflow: hidden;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16)
`

const CenteredText = styled.h5`
    display: inline;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const Logo = styled.img`
    height: 48px;
    padding-left: 10px;
    width: 85px;
`

const Header = ({text}) => {
    return (
        <TopNav>
            <Logo src={logo}/>
            <CenteredText>{text}</CenteredText>
        </TopNav>
    )
}

export default Header