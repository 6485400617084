import styled, { css } from 'styled-components';

export const ThemeTileWrapper = styled.div`
  width: 24%;
  float: left;
  padding: 14px;

  & .theme-tile {
    display: block;
    position: relative;
    background-color: #696969;
    padding: 10px;
    height: calc(100vw * 3 / 29);

    .ribbon {
      width: 100px;
      height: 100px;
      overflow: hidden;
      position: absolute;
      top: -10px;
      right: -10px;
    }
    .ribbon__content {
      left: -10px;
      top: 10px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      position: absolute;
      display: block;
      width: 150px;
      padding: 10px 0;
      background-color: rgb(232, 86, 42);
      box-shadow: 0 0px 10px;
      color: #fff;
      font-weight: bold;
      text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
      text-transform: uppercase;
      text-align: center;
      border: 2px dotted #fff;
      outline: 5px solid rgb(236, 116, 80);
    }
    .ribbon::before {
      top: 0;
      left: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid rgb(244, 171, 149);
      box-sizing: content-box;
    }
    .ribbon::after {
      bottom: 0;
      right: 0;
      border-top-color: transparent;
      border-right-color: transparent;
      position: absolute;
      z-index: -1;
      content: '';
      display: block;
      border: 5px solid rgb(244, 171, 149);
    }
  }

  &.Bar {
    width: 100%;
    float: none;
    padding: 0;
    border-bottom: 1px solid rgba(120, 120, 120, 0.5);

    .theme-tile {
      height: calc(100vw * 1.7 / 29);
      padding: 10px 10px 16px 10px;
    }
  }
`;

export const Thumbnail = styled.img`
  display: block;
  max-width: 100%;
  max-height: calc(100% - 20px);
  width: auto;
  height: auto;
`;

export const ThemeName = styled.div`
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  color: #f9f9f9;
  font-size: 0.7em;

  &.Bar {
    text-align: left;
    padding-left: 10px;
  }
`;

export const ButtonOverlay = styled.div`
  display: none;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.6);
  text-align: center;
  justify-content: center;

  ${(props) =>
    props.visible &&
    css`
      display: flex;
    `}
`;

export const Button = styled.div`
  display: block;
  padding: 10px 20px;
  align-self: center;
  background-color: #54d388;
  color: white;
  cursor: pointer;
`;

export const Section = styled.div`
  padding: 1em 0;
  margin-bottom: 1em;
  border-bottom: solid 1px #f6f6f6;

  h3 {
    font-size: 1em;
    text-transform: none;
    color: #666666;
  }

  span {
    top: 1px;
    font-size: 0.8em;
    color: #919191;
    font-weight: 400;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 20000;
  overflow: scroll;
  padding: 0 2.1em;
`;

export const Icon = styled.div`
  float: right;
  margin-top: 15px;
  color: gray;
  cursor: pointer;
`;
