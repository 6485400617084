import React, { useContext, useState } from 'react';
import { AppContext } from '../utils/contexts';
import { useHistory } from 'react-router-dom';
import Countdown from 'react-countdown';
import { useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const CloseButton = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
  width: 20px;
  svg {
    cursor: pointer;
    color: #c6c6c6;
    line-height: 80%;
    font-size: 1.2em;
    font-style: normal;
    font-weight: lighter;
    margin-right: 0.5rem;
    flex: 0 0 2rem;
  }
`;

const SiteCouponModal = (props) => {
  const site = props.site;
  const { enqueueSnackbar } = useSnackbar();
  const { couponCode } = useContext(AppContext);
  const history = useHistory();

  const currentTimestamp = +new Date().getTime();
  const date = +new Date(site.created_at).getTime();
  const threeDaysAfter = date + 1000 * 60 * 60 * 24 * 3;
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
  };

  let modal_position = (window.location.pathname === `/sites/${site.id}/select-plan`)
    ? {top: '5px'}
    : {top: 'unset', bottom: '10px'}

  const copyToClip = () => {
    navigator.clipboard.writeText(couponCode);
    enqueueSnackbar('Copied to clipboard. Use the code on the payment page.', {
      variant: 'success',
    });
    if (window.location.pathname !== `/sites/${site.id}/select-plan`) {
      history.push(`/sites/${site.id}/upgrade`);
    }
  };

  const renderer = ({ formatted }) => {
    return (
      <div className="countdown">
        {formatted.days > 0 && (
          <div>
            <span>{formatted.days}</span>:
          </div>
        )}
        <span>{formatted.hours}</span>:<span>{formatted.minutes}</span>:
        <span>{formatted.seconds}</span>
      </div>
    );
  };

  return (
    open &&
    site &&
    threeDaysAfter > currentTimestamp && (
      <div className="modal site-coupon" style={modal_position}>
        <div className="modal-content site-coupon">
          <CloseButton onClick={handleClose}>
            <CloseIcon />
          </CloseButton>
          <h3>Special One Time Offer!</h3>
          <h4>Get 100% off for first month on all plans!</h4>

          <Countdown date={threeDaysAfter} renderer={renderer} />

          <div className="modal-button-group">
            <a className="button button-row" onClick={(e) => copyToClip()}>
              Click here to copy your promo code
            </a>
          </div>
        </div>
      </div>
    )
  );
};

export default SiteCouponModal;
