import FormControl from '@mui/material/FormControl/FormControl';
import TextField from '@mui/material/TextField/TextField';
import FormHelperText from '@mui/material/FormHelperText/FormHelperText';
import React, { Fragment } from 'react';
import {
  Box,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import Switch from '@mui/material/Switch';
import Button from '../../shared/Button';
import useAPI from '../../utils/api';
import { useSnackbar } from 'notistack';

export default function ProviderForm({
  provider,
  connection,
  onChange,
  lists,
}) {
  const settings = provider.settings;
  const { enqueueSnackbar } = useSnackbar();
  const API = useAPI();

  if (provider.key === 'mad_mimi_api') {
    return (
      <form className="Form" autoComplete="off">
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection.username}
            id="username"
            label="Username"
          />
          <FormHelperText>
            This can be your MadMimi username or email address
          </FormHelperText>
        </FormControl>
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection.api_key}
            id="api_key"
            label="MadMimi API Key"
          />
          <FormHelperText>
            You can find your Secret API Key within MadMimi {'>'} Account {'>'}{' '}
            API tab
          </FormHelperText>
        </FormControl>
      </form>
    );
  }

  const onListChange = (event) => {
    event.target.id = 'remote_id';
    onChange(event);
  };

  const handleDoubleOptinChange = (event) => {
    event.target.id = 'double_optin';
    event.target.value = event.target.checked;
    onChange(event);
  };

  const sendTestWebhookEvent = async (url, method) => {
    const [ok, response] = await API.testWebhook(url, method);
    if (ok) {
      enqueueSnackbar('Test request sent Successfully!', {
        variant: 'success',
      });
    } else {
      enqueueSnackbar('Please check URL', {
        variant: 'error',
      });
    }
  };

  return (
    <div className="Form">
      {lists && lists.length > 0 && (
        <Fragment>
          <FormControl fullWidth>
            <InputLabel>Choose list ID</InputLabel>
            <Select
              required={provider.key === 'sailthru' ? false : true}
              onChange={onListChange}
              value={connection.remote_id}
              id="remote_id"
            >
              {lists.map((list) => (
                <MenuItem key={list.id} value={list.id}>
                  {list.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {settings.double_optin && (
            <FormControl fullWidth>
              <FormControlLabel
                control={
                  <Switch
                    color="secondary"
                    onChange={handleDoubleOptinChange}
                    checked={connection.double_optin === true}
                  />
                }
                label="Use double opt-in?"
              />
            </FormControl>
          )}
        </Fragment>
      )}

      {settings.requires_account_id && (
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection?.credentials?.username}
            id="username"
            label={`Enter your Account ID from ${provider.title}`}
          />
        </FormControl>
      )}

      {settings.requires_api_key && (
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection.api_key}
            id="api_key"
            label={`Enter your API Key from ${provider.title}`}
          />
        </FormControl>
      )}

      {settings.requires_api_secret && (
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection?.credentials && connection?.credentials['secret']}
            id="api_secret"
            label={`Enter your API Secret Key from ${provider.title}`}
          />
        </FormControl>
      )}

      {settings.requires_app_url && (
        <FormControl fullWidth>
          <TextField
            required
            type="url"
            onChange={onChange}
            value={connection.app_url}
            id="app_url"
            label={`Enter your API URL from ${provider.title}`}
          />
        </FormControl>
      )}

      {settings.requires_webhook_url && (
        <Box>
          <FormControl
            fullWidth
            sx={{
              gap: 1,
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            {provider.key === 'webhooks' && (
              <>
                <InputLabel id="method-label">Method</InputLabel>
                <Select
                  required
                  labelId="method-label"
                  label="Method"
                  onChange={onChange}
                  value={connection.webhook_method || 'post'}
                  name="webhook_method"
                  size="small"
                >
                  <MenuItem key="POST" value="post">
                    POST
                  </MenuItem>
                  <MenuItem key="GET" value="get">
                    GET
                  </MenuItem>
                </Select>
              </>
            )}
            { (provider.key !== 'zapier' || (provider.key === 'zapier' && connection?.webhook_url)) && (
              <TextField
                required={provider.key === 'zapier' ? false : true}
                type="url"
                onChange={onChange}
                value={connection.webhook_url || 'https://'}
                id="webhook_url"
                label="Enter Webhook URL"
                size="small"
                fullWidth
              />
            )}
            {provider.key === 'webhooks' && (
              <Button
                fullWidth
                sx={{ width: '240px', padding: '6px 0px' }}
                onClick={() =>
                  sendTestWebhookEvent(
                    connection.webhook_url,
                    connection.webhook_method
                  )
                }
              >
                Send Test Request
              </Button>
            )}
          </FormControl>
        </Box>
      )}

      {settings.requires_embed_code && (
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection.embed_code}
            id="embed_code"
            label="Enter your embed code from MyEmma"
            multiline
            rows={4}
          />
        </FormControl>
      )}

      {settings.requires_list_api_id && (
        <FormControl fullWidth>
          <TextField
            required
            onChange={onChange}
            value={connection.list_api_id}
            id="list_api_id"
            label={`Enter your List API ID from ${provider.title}`}
          />
        </FormControl>
      )}
    </div>
  );
}
