import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from 'react';
import useDebounce from '../../utils/useDebounce';
import { initEditor, destroyEditor } from './utils/init-editor.util';
import { useHistory } from 'react-router-dom';
import { EditorContext } from '../../utils/contexts';
import ImagePosition from './panels/ImagePosition';

export const PopupContainer = ({
  hellobar,
  updateSettings,
  setIsToolbarLoaded,
  ...props
}) => {
  const debouncedSettings = useDebounce(props.settings, 200);
  const elements = useMemo(() => [], []);
  const [skipRendering, setSkipRendering] = useState(false);
  const [popup, setPopup] = useState(null);
  const [rendered, setRendered] = useState(false);
  const history = useHistory();
  const { siteSettings } = useContext(EditorContext);

  const onFocus = (id) => {
    if (
      [
        'conversion_cta_text',
        'thank_you_text',
        'question',
        'answer1',
        'answer2',
      ].includes(id)
    ) {
      return;
    }
    const path = history.location.pathname.split('/');
    path.pop();
    history.push({ pathname: [...path, 'design'].join('/') });
    hellobar.pause();
  };

  const onBlur = () => {
    hellobar.start();
  };

  useEffect(() => {
    setSkipRendering(false);
  }, [props.type, props.settings?.subtype]);

  useEffect(() => {
    const mountedHandler = async (e) => {
      await initEditor({
        customFonts: siteSettings.font_uploads,
        onUpdate: updateSettings,
        setIsToolbarLoaded,
        id: e.data.id,
        type: props.type,
        subtype: props.settings?.subtype,
        onFocus,
        onBlur,
        shopifySite: props.settings?.shopify_site
      });
      window.tinymce.get().forEach((e) => e.ui.hide());
      window.tinymce.EditorManager.activeEditor.ui.show();
    };

    const unmountHandler = (e) => {
      destroyEditor(e.data?.id);
      if (window.tinymce.EditorManager.activeEditor) {
        window.tinymce.EditorManager.activeEditor.ui.show();
      }
    };

    const removeHandler = () => {
      hellobar.removeEventListener('show', showHandler);
      hellobar.removeEventListener('show.page', showPageHandler);
      hellobar.removeEventListener('remove', removeHandler);
      hellobar.removeEventListener('editable.unmount', unmountHandler);
      hellobar.removeEventListener('editable.mounted', mountedHandler);
      destroyEditor();
      if (window.tinymce.EditorManager.activeEditor) {
        window.tinymce.EditorManager.activeEditor.ui.show();
      }
    };

    const showHandler = () => {
      hellobar.addEventListener('remove', removeHandler);
    };

    const showPageHandler = () => {
      window.tinymce.get()[0].ui.setEnabled(true);
    };

    hellobar.addEventListener('editable.mounted', mountedHandler);
    hellobar.addEventListener('editable.unmount', unmountHandler);
    hellobar.addEventListener('show', showHandler);
    hellobar.addEventListener('show.page', showPageHandler);

    return removeHandler;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.type, props.settings?.subtype]);

  const renderPreview = useCallback(
    (settings) => {
      const previewElement = JSON.parse(JSON.stringify(settings));
      previewElement.template_name =
        `${settings.type}_${settings.element_subtype}`.toLowerCase();

      const popup = hellobar.createPopup(previewElement);
      setPopup(popup);

      if (hellobar.popups.length > 0) {
        hellobar.remove();
      }
      return hellobar.show(popup);
    },
    [hellobar]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const renderPopup = async () => {
      debouncedSettings.font =
        siteSettings.fonts[debouncedSettings.font_id] ||
        debouncedSettings.font_id;

      window.$RENDERED_ELEMENT = debouncedSettings;
      if (elements && debouncedSettings) {
        if (skipRendering) {
          popup.update(debouncedSettings);
        } else {
          await renderPreview(debouncedSettings);
          setRendered(true);
          setSkipRendering(true);
        }
      }
    };
    renderPopup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSettings,
    props.type,
    props.settings?.subtype,
    props.settings?.placement,
  ]);

  return rendered ? (
    <ImagePosition updateSettings={updateSettings} settings={props.settings} />
  ) : null;
};
