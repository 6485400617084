import {useContext} from 'react';
import {SiteContext, UserContext} from "./contexts";
import {auth} from "./auth";

async function GET(path, token) {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1${path}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  try {
    if (response.status === 401) {
      return [false, {status: 401, message: 'Unauthorized'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }

    const json = await response.json();
    return [response.ok, json];
  } catch (e) {
    return [response.ok, {status: response.status}];
  }
}

async function DOWNLOAD(path, token, filename) {
  const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1${path}`, {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    }
  });

  if (response.status === 401) {
    return [false, {message: 'Unauthorized'}];
  }

  const blob = await response.blob();
  const file = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = file;
  link.download = filename;
  link.click();
}

async function UPDATE(method, path, token, data) {
  const isFormData = data instanceof FormData
  const body = isFormData ? data : JSON.stringify(data)

  const formDataHeaders = {'Authorization': `Bearer ${token}`}
  const jsonHeaders = {'Content-Type': 'application/json', ...formDataHeaders}

  try {
    const response = await fetch(`${process.env.REACT_APP_API_HOST}/api/v1${path}`, {
      method: method,
      cache: 'no-cache',
      body: body,
      headers: isFormData ? formDataHeaders : jsonHeaders
    });

    if (response.status === 401) {
      return [false, {message: 'Unauthorized'}];
    }

    if (response.status === 404) {
      return [false, {message: 'Not Found'}];
    }

    if (response.status > 499) {
      return [false, {message: 'Server Error'}];
    }

    if (response.status === 204) {
      return [response.ok, null];
    }

    const json = await response.json();
    return [response.ok, json];
  } catch (e) {
    console.log(e)
    return [false, { message: e.message }]
  }
}

async function POST(path, token, data) {
  return UPDATE('POST', path, token, data);
}

async function PATCH(path, token, data) {
  return UPDATE('PATCH', path, token, data);
}

async function PUT(path, token, data) {
  return UPDATE('PUT', path, token, data);
}

async function DELETE(path, token) {
  return UPDATE('DELETE', path, token);
}

export const API = {
  popups: (token, siteId) => GET(`/sites/${siteId}/popups`, token),
  contactLists: (token, siteId) => GET(`/sites/${siteId}/contact_lists`, token),
  contactList: (token, siteId, contactListId) => GET(`/sites/${siteId}/contact_lists/${contactListId}`, token),
  summary: (token, siteId) => GET(`/sites/${siteId}/summary`, token),
  team: (token, siteId) => GET(`/sites/${siteId}/team`, token),
  updateRole: (token, siteId) => GET(`/sites/${siteId}/team`, token),
  profile: (token, siteId) => GET(`/profile`, token),
  updateProfile: (token, profile) => PATCH(`/profile`, token, profile),
  invoices: (token, siteId) => GET(`/sites/${siteId}/invoices`, token)
}

const useAPI = () => {
  const token = auth.getToken();
  const user = useContext(UserContext);
  const site = useContext(SiteContext);
  const siteId = site?.id;
  const plan = site?.plan;
  const currentRole = site?.current_role;

  return {
    siteId,
    site,
    plan,
    currentRole,
    popups: () => GET(`/sites/${siteId}/popups`, token),
    popupSettings: (siteId, popupId) => {
      if (popupId === 'new') {
        return GET(`/sites/${siteId}/popups/new_settings.json`, token)
      } else {
        return GET(`/sites/${siteId}/popups/${popupId}/settings.json`, token)
      }
    },
    uploadImage: (siteId, file) => {
      const form = new FormData();
      form.append("file", file);
      return POST(`/sites/${siteId}/image_uploads`, token, form)
    },
    deleteImage: (id) => DELETE(`/sites/${siteId}/image_uploads/${id}`, token),
    identities: () => GET(`/sites/${siteId}/identities`, token),
    deleteIdentity: (id) => DELETE(`/sites/${siteId}/identities/${id}`, token),
    customFonts: () => GET(`/sites/${siteId}/font_uploads`, token),
    uploadFont: (siteId, file, family) => {
      const form = new FormData();
      form.append("file", file);
      form.append('font_family_name', family)
      return POST(`/sites/${siteId}/font_uploads`, token, form)
    },
    deleteCustomFont: (id) => DELETE(`/sites/${siteId}/font_uploads/${id}`, token),
    siteSettings: () => GET(`/sites/${siteId}/settings.json`, token),
    siteTheme: () => GET(`/sites/${siteId}/site_themes`, token),
    rules: () => GET(`/sites/${siteId}/rules`, token),
    rulePopups: (ruleId, scope = 'all') => GET(`/sites/${siteId}/rules/${ruleId}/popups?scope=${scope}`, token),
    popupTags: () => GET(`/sites/${siteId}/popup_tags`, token),
    popupTogglePause: (popupId) => PUT(`/sites/${siteId}/popups/${popupId}/toggle`, token),
    popupDelete: (popupId) => DELETE(`/sites/${siteId}/popups/${popupId}`, token),
    popupCopy: (popupId, copyToSiteId) => POST(`/sites/${siteId}/popups/${popupId}/copy?copy_site_id=${copyToSiteId}`, token),
    popupGraphSummary: (popupId, from, to) => GET(`/sites/${siteId}/popups/${popupId}/summary?from=${from}&to=${to}`, token),
    contactLists: (scope = 'active') => GET(`/sites/${siteId}/contact_lists?scope=${scope}`, token),
    contactList: (contactListId, page) => GET(`/sites/${siteId}/contact_lists/${contactListId}?page=${page}`, token),
    createContactList: (data) => POST(`/sites/${siteId}/contact_lists`, token, data),
    updateContactList: (id, data) => PATCH(`/sites/${siteId}/contact_lists/${id}`, token, data),
    testWebhook: (url, method) => POST(`/sites/${siteId}/test_webhook`, token, {url, method}),
    exportContactList: (id) => POST(`/sites/${siteId}/contact_lists/${id}/export`, token),
    deleteContactList: (id) => DELETE(`/sites/${siteId}/contact_lists/${id}`, token),
    connectContactList: (data) => POST(`/sites/${siteId}/connections`, token, data),
    connectionLists: (id) => GET(`/sites/${siteId}/connections/${id}/lists`, token),
    contactListConnection: (provider) => GET(`/sites/${siteId}/connections/${provider}`, token),
    summary: (from, to) => GET(`/sites/${siteId}/summary?from=${from}&to=${to}`, token),
    team: () => GET(`/sites/${siteId}/team`, token),
    profile: () => GET(`/profile`, token),
    subscription: (siteId) => GET(`/sites/${siteId}/subscription`, token),
    updateProfile: (profile) => PATCH(`/profile`, token, profile),
    updatePassword: (passwords) => PUT(`/profile/password`, token, passwords),
    updateSite: (site) => PATCH(`/sites/${site.id}`, token, site),
    createSite: (site) => POST(`/sites`, token, site),
    deleteSite: (site) => DELETE(`/sites/${site.id}`, token),
    createCheckoutSession: (priceId, code, trial) => {
      const params = {code, customer_id: site.customer_id || user.customer_id, price_id: priceId, trial_days_without_card: trial ? true : false};
      return POST(`/sites/${siteId}/checkout_sessions`, token, params);
    },
    checkoutSession: (sessionId) => GET(`/sites/${siteId}/checkout_sessions/${sessionId}`, token),
    invoices: () => GET(`/sites/${siteId}/invoices`, token),
    checkSiteInstalled: () => GET(`/sites/${siteId}/check`, token),
    proration: (planId, coupon) => GET(`/sites/${siteId}/proration?plan_id=${planId}&coupon=${coupon}`, token),
    checkCoupon: (code, priceId) => GET(`/coupon/?code=${code}&price_id=${priceId}`, token),
    upgrade: (plan_id, coupon) => PATCH(`/sites/${siteId}/upgrade`, token, {plan_id, coupon}),
    createRule: (rule) => POST(`/sites/${siteId}/rules`, token, rule),
    updateRule: (rule) => PATCH(`/sites/${siteId}/rules/${rule.id}`, token, rule),
    deleteRule: (rule) => DELETE(`/sites/${siteId}/rules/${rule.id}`, token),
    updateTheme: (theme) => PUT(`/sites/${siteId}/update_theme`, token, theme),
    deleteTheme: () => DELETE(`/sites/${siteId}/delete_theme`, token),
    downloadReport: (siteId, popupId, range, reportDays = 30) => {
      let from;
      let to;
      if (range) {
        from = range.from.format('yyyy-MM-DD')
        to = range.to.format('yyyy-MM-DD')
      }
      let filename = '';
      if (popupId) {
        filename = `site-statistics_${siteId}_${popupId}_${from}-${to}_Last-${reportDays}-days.csv`;
      } else {
        filename = `site-statistics_${siteId}_${from}-${to}_Last-${reportDays}-days.csv`;
      }
      return DOWNLOAD(`/sites/${siteId}/report?days=${reportDays}&from=${from}&to=${to}&popup_id=${popupId}`, token, filename);
    },
    updateBillingInformation: (info) => PUT(`/sites/${siteId}/billing_information`, token, info),
    inviteUser: (invitation) => POST(`/sites/${siteId}/invites`, token, invitation),
    updateRole: (role) => PATCH(`/sites/${role.siteId}/team`, token, role),
    updateCreditCard: (stripeToken) => {
      return PATCH(`/sites/${siteId}/payment_methods`, token, {token: stripeToken})
    },
    braintreeToken: () => POST(`/braintree_tokens`, token),
    subscribeWithPayPal: (payment_method_nonce, plan_id, coupon) => {
      return PATCH(`/sites/${siteId}/upgrade`, token, {payment_method_nonce, plan_id, coupon})
    },
    emailDeveloper: (site, email) => POST(`/sites/${site.id}/email_developer`, token, {email}),
    purchaseOnboarding: (site) => POST(`/sites/${site.id}/purchase_onboarding`, token),
    purchaseEmailValidation: (site, credit, add_on_type) => {
      const params = {credit: credit, add_on_type: add_on_type}
      return POST(`/sites/${site.id}/purchase_add_ons`, token, params)
    },
    savePopup: (payload, {pause}) => {
      const data = {...payload, paused_at: pause ? new Date() : null}

      if (payload.id) {
        return PATCH(`/sites/${siteId}/popups/${payload.id}`, token, data)
      } else {
        return POST(`/sites/${siteId}/popups`, token, data)
      }
    },
    raiseSupport: (message) => POST(`/sites/${siteId}/support_request.json`, token, {message: message}),
    reactivateSubscription: (subscription) => POST(`/sites/${siteId}/reactivate_subscription`, token, {stripe_subscription_id: subscription.stripe_subscription_id} )
  }
}

export default useAPI;
