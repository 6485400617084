import React, {Fragment, useContext, useState, useMemo} from "react";

import SimpleTextEditorPanel from "../components/SimpleTextEditorPanel";
import RichTextEditorPanel from "../components/RichTextEditorPanel";
import Switcher from "../components/Switcher";
import {Label} from "../Steps/components";
import {EditorContext} from "../../../utils/contexts";
import {extractContent, generateHtml, parseCss} from "../utils";

export default function ContentEditorPanel({ defaultContent, onUpdate, contentKey }) {
  const {settings} = useContext(EditorContext)
  const html = settings[contentKey] || ''
  const [advanced, setAdvanced] = useState(html.includes('class="advanced"') || settings.type !== 'Bar')
  
  const props = useMemo(() => ({
    defaultFont: settings.font_id,
    onUpdate: onUpdate,
    defaultContent: defaultContent,
    html: html
  }), [defaultContent, html, onUpdate, settings.font_id])

  const simplifyHtml = () => {
    const css = parseCss(html, props.defaultFont)
    const text = extractContent(html || defaultContent)
    return generateHtml(text, css, 'simple')
  }

  const toggleAdvanced = () => {
    if (advanced) {
      onUpdate(simplifyHtml())
    }
    setAdvanced(!advanced)
  }

  const options = {
    plugins: ['headline', 'caption', 'content'].includes(contentKey) ? ['autolink link'] : [],
    toolbar: ['headline', 'caption', 'content'].includes(contentKey) ? 'link' : ''
  }

  return (
    <Fragment>
      <Label>
        <span>Simple text editor</span>
        <Switcher checked={!advanced} onChange={toggleAdvanced}/>
      </Label>
      {!advanced && <SimpleTextEditorPanel {...props}/>}
      {advanced && <RichTextEditorPanel {...props} options={options} shopifySite={settings.shopify_site}/>}
    </Fragment>
  )
}
