import tinymce from 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/models/dom';
import 'tinymce/skins/ui/oxide-dark/skin.min.css';

import 'tinymce/plugins/emoticons';
import 'tinymce/plugins/emoticons/js/emojis';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/code';
import 'tinymce/plugins/quickbars';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/media';
// Content styles, including inline UI like fake cursors
/* eslint import/no-webpack-loader-syntax: off */
import skinCss from '!!raw-loader!tinymce/skins/ui/oxide-dark/content.min.css';

import { FONT_FAMILIES } from '../constants';
import { fontsUrl } from '../../../utils/useFonts';

const allowEditing = (editor) => {
  const getBody = (event) => event.target.bodyElement;
  const forEachDataTag = (body, handler) => {
    body.querySelectorAll('.data-tag').forEach((element) => handler(element));
  };

  editor.on('BeforeExecCommand', (e) => {
    const body = getBody(e);
    forEachDataTag(body, (element) => {
      element.setAttribute('contenteditable', true);
    });
  });

  editor.on('ExecCommand', (e) => {
    const body = getBody(e);
    forEachDataTag(body, (element) => {
      element.setAttribute('contenteditable', false);
    });
  });
};

const addHandlers = ({
  editor,
  onUpdate,
  onFocus,
  onBlur,
  setIsToolbarLoaded,
}) => {
  editor.on('focus', () => {
    onFocus(editor.id);
    editor.ui.setEnabled(true);
  });
  editor.on('blur', () => {
    editor.ui.setEnabled(false);
    onUpdate({ [editor.id]: editor.getContent() });
    onBlur(editor.id);
  });
  editor.on('init', () => {
    editor.ui.setEnabled(false);
    editor.ui.hide();
    setIsToolbarLoaded(true);
    const idsForExtension = ['headline', 'caption', 'content'];
    if (!idsForExtension.includes(editor.id)) {
      return;
    }
    editor.ui.registry.addButton('uilink', {
      icon: 'link',
      onAction: () => editor.execCommand('mceLink'),
    });
  });
  editor.on('deactivate', () => {
    editor.ui.hide();
  });
  editor.on('activate', () => {
    editor.ui.show();
  });
};

const addCustomButtons = function (editor, shopifySite) {
  if (window.hellobarSiteSettings.modules_filename === 'modules.js') {
    return;
  }
  editor.ui.registry.addMenuButton('mergefields', {
    text: 'Merge Fields',
    fetch: function (callback) {
      const items = [
        {
          type: 'nestedmenuitem',
          text: 'Peronal',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Name',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{name}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Email',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{email}}</span>'
                  );
                },
              },
            ];
          },
        },
        {
          type: 'nestedmenuitem',
          text: 'Geolocation',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Country',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{geolocation.countryName}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'City',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{geolocation.city}}</span>'
                  );
                },
              },
            ];
          },
        },
        {
          type: 'nestedmenuitem',
          text: 'Countdown',
          getSubmenuItems: function () {
            return [
              {
                type: 'menuitem',
                text: 'Seconds',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag" title="Seconds">{{cd_seconds}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Minutes',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{cd_minutes}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Hours',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{cd_hours}}</span>'
                  );
                },
              },
              {
                type: 'menuitem',
                text: 'Days',
                onAction: function () {
                  editor.insertContent(
                    '<span class="data-tag">{{cd_days}}</span>'
                  );
                },
              },
            ];
          },
        },
      ];

      if (shopifySite) {
        items.push(
          {
            type: 'nestedmenuitem',
            text: 'Shopify Cart',
            getSubmenuItems: function () {
              return [
                {
                  type: 'menuitem',
                  text: 'Product Title',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pt}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Description',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pd}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Price',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pp}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Product Image',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_pi}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Items Count',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_c_cnt}}</span>'
                    );
                  },
                },
              ];
            },
          },
          {
            type: 'nestedmenuitem',
            text: 'Recommended Product',
            getSubmenuItems: function () {
              return [
                {
                  type: 'menuitem',
                  text: 'Title',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_t}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Description',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_d}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Price',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_p}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Image',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_i}}</span>'
                    );
                  },
                },
                {
                  type: 'menuitem',
                  text: 'Link',
                  onAction: function () {
                    editor.insertContent(
                      '<span class="data-tag">{{s_rp_l}}</span>'
                    );
                  },
                },
              ];
            },
          }
        )
      }
      callback(items);
    },
  });
};

export const initEditor = async ({
  customFonts,
  onUpdate,
  onFocus,
  onBlur,
  setIsToolbarLoaded,
  id,
  type,
  subtype,
  shopifySite
}) => {
  if (tinymce.get(id)) {
    tinymce.get(id).remove();
  }

  const newFonts = customFonts
    .map((f) => `${f.font_name}=${f.font_name}`)
    .join(';');
  const oldFonts = Object.keys(FONT_FAMILIES)
    .map((key) => `${FONT_FAMILIES[key]}=${key}`)
    .join(';');
  const fontFormats = newFonts ? newFonts + ';' + oldFonts : oldFonts;

  let plugins = 'emoticons lists code';

  if (['headline', 'caption', 'content', 'question'].includes(id)) {
    plugins += ' autolink quickbars link image media';
  }

  await tinymce.init({
    menubar: false,
    plugins: plugins,
    media_alt_source: false,
    media_poster: false,
    emoticons_database: 'emojis',
    toolbar: 'mergefields | fontfamily fontsize removeformat bold italic underline emoticons forecolor backcolor | uilink alignleft aligncenter alignright indent outdent lineheight | image media',
    toolbar_mode: 'floating',
    elementpath: false,
    font_family_formats: fontFormats,
    branding: false,
    font_size_formats:
      '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 22pt 24pt 26pt 28pt 30pt 32pt 34pt 36pt 40pt 44pt 48pt 60pt 72pt 96pt',
    indentation: '20px',
    font_css: fontsUrl(),
    content_style: [
      skinCss.toString(),
      '.tox.tox-tinymce-inline .tox-editor-header { border-radius: 0; }',
      'span.data-tag { color: gray; } .editable { outline: none; }',
      // '.tox-dialog__body-nav-item.tox-tab:nth-child(2), .tox-dialog__body-nav { display: none !important; }'
    ].join("\n"),
    skin: false,
    content_css: false,
    default_link_target: '_blank',
    target_list: false,
    noneditable_class: 'data-tag',
    selector: `.${type}.${subtype} #${id}.editable`,
    inline: true,
    placeholder: null,
    fixed_toolbar_container: '#toolbar',
    toolbar_persist: true,
    contextmenu: 'media',
    quickbars_selection_toolbar: 'bold italic underline forecolor link',
    quickbars_insert_toolbar: '',
    quickbars_image_toolbar: 'image imageoptions | alignleft aligncenter alignright',
    format_noneditable_selector: 'span.data-tag',
    setup: (editor) => {
      editor.ui.registry.addContextMenu('quickimage', {
        update: function (element) {
          return ['image'];
        },
      });
      addCustomButtons(editor, shopifySite);
      allowEditing(editor);
      addHandlers({
        editor,
        onUpdate,
        onFocus,
        setIsToolbarLoaded,
        onBlur,
      });
    },
  });
};

export const destroyEditor = (id) => {
  if (id) {
    const editor = window.tinymce.get(id);
    if (editor) {
      editor.remove();
    }
  } else {
    window.tinymce.get().forEach((e) => {
      e.remove();
    });
  }
};
